import React from 'react';

const ImportSection = () => {
  return (
    <section className="Import">
      <div className="row360section">
        <div className="col-lg-6 col-md-12 col-xl-6">
          <div className="text-azul s_features_content pl_120 text-justify">
            <br />
            <br />
            <br />
            <h2
              className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
              data-wow-delay="0.4s"
            >
              plataforma inmersiva multidispositivos
            </h2>

            <h3
              className="f_size_18 l_height30 f_400 wow fadeInRight"
              data-wow-delay="0.5s"
            >
              Capacitación y Soporte:
            </h3>
            <p
              className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
              data-wow-delay="0.5s"
            >
              - Aumenta la productividad de tus equipos
            </p>
            <p
              className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
              data-wow-delay="0.5s"
            >
              - Maximiza el potencial de las personas <br />
            </p>
            <p
              className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
              data-wow-delay="0.5s"
            >
              - Llega más lejos con tus mensajes
            </p>
            <h3
              className="f_size_18 l_height30 f_400 wow fadeInRight"
              data-wow-delay="0.5s"
            >
              Procesos, buscando la mejor experiencia humano – máquina, para acortar la brecha entre la teoría y la práctica.
              <br />
              Todo esto en una única plataforma modular para la creación e implementación de simulaciones industriales.
            </h3>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-xl-6">
          <div className="card-body-360">
            <div className="img-card">
              <a href="/ceo">
                <img
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/vr360.png"
                  alt="Descripción de la imagen"
                  style={{ maxWidth: '100%', height: 'auto' }} // Asegura que la imagen se ajuste bien
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImportSection;
