import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Usamos axios para las solicitudes
const apiUrl = process.env.REACT_APP_API_URL;

const QRPayment = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [paymentReason, setPaymentReason] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState(''); // Estado para almacenar la URL del QR
  const [qrRegulador, setqrRegulador] = useState(''); // Estado para almacenar la URL del QR Regulador
  const [qrAsfi, setqrAsfi] = useState(''); // Estado para almacenar la URL del QR Asfi

  // Función para obtener el código QR desde la API
  const fetchQRCode = async () => {
    try {
      const response = await axios.get(apiUrl + 'getqrdataimg');
      const qrPayment = response.data.url + response.data.qrdata.qr;
      const qrRegular = response.data.url + response.data.qrdata.regulado;
      const asfiData = response.data.url + response.data.qrdata.asfi;

      setQrCodeUrl(qrPayment);
      setqrRegulador(qrRegular);
      setqrAsfi(asfiData);
    } catch (error) {
      console.error('Error al obtener el código QR:', error);
      setQrCodeUrl(''); // En caso de error, limpia la URL
    }
  };

  // Usar useEffect para obtener el QR al cargar el componente
  useEffect(() => {
    fetchQRCode();
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleReasonChange = (event) => {
    setPaymentReason(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      setUploadStatus('Por favor, selecciona un archivo antes de enviar.');
      return;
    }

    if (!paymentReason) {
      setUploadStatus('Por favor, ingresa el motivo del pago.');
      return;
    }

    try {
      // Crear FormData para enviar el archivo y el motivo del pago
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('reason', paymentReason);

      // Enviar el formulario al endpoint para guardar el comprobante
      const response = await axios.post(apiUrl + 'savecomprobante', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Verificar la respuesta del servidor
      if (response.status === 200) {
        setUploadStatus('Respaldo enviado correctamente con el motivo del pago.');
        setSelectedFile(null); // Limpiar archivo seleccionado
        setPaymentReason(''); // Limpiar campo de motivo
      } else {
        throw new Error('Error al enviar el comprobante.');
      }
    } catch (error) {
      console.error('Error al enviar el comprobante:', error);
      setUploadStatus('Hubo un error al enviar el comprobante. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <section className="qr-payment">
      <div className="qr-payment-container">
        <div className="qr-code">
          {qrCodeUrl ? (
            <img src={qrCodeUrl} alt="Código QR" />
          ) : (
            <p>Cargando código QR...</p>
          )}
        </div>
        <div className="upload-form">
          <h3>Subir Respaldo de Pago</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="paymentReason">Motivo del Pago</label>
              <input
                type="text"
                id="paymentReason"
                value={paymentReason}
                onChange={handleReasonChange}
                placeholder="Escribe el motivo del pago"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="fileInput">Seleccionar archivo (PDF, JPG, PNG)</label>
              <input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                accept=".pdf,.jpg,.png"
                required
              />
            </div>
            <button type="submit" className="btn-submit">Enviar Respaldo</button>
          </form>
          {uploadStatus && <p className="upload-status">{uploadStatus}</p>}
        </div>
      </div>

      <div className="regulador">
        <h3>Contamos con múltiples formas de pago</h3>
        {qrRegulador ? (
          <img className="imgregulador" src={qrRegulador} alt="Regulado" />
        ) : (
          <p>Cargando código de regulador...</p>
        )}
      </div>

      <div className="asfi">
        <center>
          <p>
            Pagos con tarjeta, QR, débito directo en BCP, BNB, Tigo Money y Pago Express están supervisados.
          </p>
        </center>
        {qrAsfi ? (
          <img className="imgreguladorasfi" src={qrAsfi} alt="ASFI" />
        ) : (
          <p>Cargando código ASFI...</p>
        )}
      </div>
    </section>
  );
};

export default QRPayment;
