import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaFlask, FaHeart, FaTooth, FaCrutch, FaBrain, FaDna } from 'react-icons/fa'; // Importar íconos como 'react-icons/fa'
const testimonialsData = [
  {

    quote: "Excelente servicio!",
    name: "Juan Morales",
    text: "La atención en Compañía Minera San Cristóbal es de primera. Nos apoyaron en cada paso de nuestro proyecto de expansión."
  },
  {

    quote: "Profesionales expertos!",
    name: "Marta Gutiérrez",
    text: "Hotel Coloso Potosí nos brindó una experiencia inolvidable. Atención excelente y ambiente muy acogedor."
  },
  {

    quote: "Soporte increíble!",
    name: "Carlos Pérez",
    text: "La empresa de tecnología PotosiSoft nos ayudó a mejorar la infraestructura de IT de nuestra organización. Muy recomendados."
  },
  {

    quote: "Ambiente agradable!",
    name: "Sara López",
    text: "Restaurante Casa de Piedra nos brindó una experiencia gastronómica de primera. Atención y calidad inmejorables."
  },
  {

    quote: "Servicio moderno!",
    name: "Pedro Flores",
    text: "ElectroPotosí nos ayudó con el equipamiento eléctrico de nuestra planta. Muy profesionales y comprometidos."
  },
  {

    quote: "Servicio confiable!",
    name: "Ana Vargas",
    text: "Agencia de viajes Potosí Tours organizó nuestra expedición a la Laguna Colorada. Excelente coordinación y guías capacitados."
  },
  {

    quote: "Innovación constante!",
    name: "Felipe Quiroga",
    text: "TechPotosí nos brindó soluciones de software personalizadas para nuestro negocio. Muy recomendados para proyectos tecnológicos."
  },
  {

    quote: "Gran atención!",
    name: "Gabriela Cruz",
    text: "La Clínica Diagnostico Potosí nos brindó una atención médica especializada con un equipo de profesionales altamente capacitados."
  },
  {

    quote: "Experiencia positiva!",
    name: "Oscar Rojas",
    text: "La Cámara de Comercio de Potosí organizó un excelente foro empresarial. Un lugar clave para hacer contactos de negocio."
  },
  {

    quote: "Excelente logística!",
    name: "Roberto Maldonado",
    text: "TransPotosí nos brindó un sistema de servicio de transporte eficiente y seguro para nuestra carga. Altamente recomendados."
  },
  {

    quote: "Profesionales en educación!",
    name: "María Fernández",
    text: "El Colegio Santa María  tiene una calidad educativa excepcional. Docentes comprometidos y un ambiente ideal."
  },
  {

    quote: "Proveedores confiables!",
    name: "Diego Gómez",
    text: "Ferretería Potosina nos proveyó de materiales tecnologicos de alta calidad para nuestro proyecto. Servicio excelente."
  },
  {

    quote: "Rápido y eficiente!",
    name: "Elena Paz",
    text: "Lavandería La Central tiene un servicio rápido y eficaz. Perfecto para quienes necesitan resultados inmediatos."
  },
  {

    quote: "Soluciones creativas!",
    name: "Nicolás Ortega",
    text: "La agencia de publicidad Potosí Creativa nos ayudó a expandir nuestra marca de manera efectiva. Excelentes campañas de marketing."
  },
  {

    quote: "Servicio impecable!",
    name: "Lorena Ávila",
    text: "Farmacia Salud Total nos brindó un sistema sencillo rápido y confiable."
  },
  {

    quote: "Gran cobertura!",
    name: "Alberto Galarza",
    text: "Seguros Potosí nos ofreció una excelente cobertura para nuestra flota de vehículos. Profesionales y atentos en todo momento."
  },
  {

    quote: "Perfecto para eventos!",
    name: "Daniel Espinoza",
    text: "Salón de Eventos Imperial Potosí nos brindó un espacio ideal para nuestra boda. Ambiente impecable y servicio de calidad. ecepcional"
  },
  {

    quote: "Innovación y tecnología!",
    name: "Verónica Chacón",
    text: "La empresa de Virgen De cotoca nos proveyó internet de alta velocidad. Perfecto para nuestras necesidades."
  },
  {

    quote: "Calidad y atención!",
    name: "Javier Serrano",
    text: "Tienda de Ropa Elegante nos brindó atención personalizada en el desarrollo del sistemas de compra y venta"
  }
];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="section-testimonial-2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h2>Testimonios Comprobados</h2>
              <div className="divider mx-auto my-4"></div>
              <p>
                Conozcamos más sobre el excelente servicio que ofrecemos. Brindamos soluciones a las necesidades más exigentes,
                abordando cada reto con precisión y dedicación. Superamos las expectativas al resolver problemas complejos con rapidez y eficacia,
                asegurando una experiencia sin inconvenientes y resultados de alta calidad.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-body">
        <Slider {...settings}>
          {testimonialsData.map((testimonial, index) => (
            <div className="testimonial-block style-2 gray-bg" key={index}>
              <i className="iconosvg">
                <svg viewBox="0 -1.5 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#f7eac7">
                  <g id="SVGRepo_iconCarrier">
                    <title>content / 18 - content, folder, open folder, data, file, storage icon</title>
                    <g transform="translate(-229.000000, -305.000000)" id="Group" stroke="#fff" strokeWidth="2">
                      <g transform="translate(227.000000, 302.000000)" id="Shape">
                        <path d="M6.99588205,13 L22,13 L18.8443072,18.4941815 C18.3092927,19.4256622 17.3170763,20 16.2428803,20 L3,20 L6.99588205,13 Z"></path>
                        <path d="M3,20 L3,5 C3,4.44771525 3.44771525,4 4,4 L9.49749189,4 C9.81366031,4 10.1112053,4.14951876 10.2998908,4.40321197 L12.7001092,7.63037563 C12.8887947,7.88406884 13.1863397,8.0335876 13.5025081,8.0335876 L18,8.0335876 C18.5522847,8.0335876 19,8.48130285 19,9.0335876 L19,12"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </i>
              <div className="client-info">
                <h4>{testimonial.quote}</h4>
                <span>{testimonial.name}</span>
                <p>{testimonial.text}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
