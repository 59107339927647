import React from "react";
const It = () => {
  return (
    <>
      <section className="s_features_section" style={{ marginTop: "100px" }}>
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="box text-center col-lg-12 m-5 p-5 d-flex flex-column justify-content-center align-items-center">
              <h2
                className="titulos_servicios f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                Consultoría IT
              </h2>
              <br />
              <h4
                className="lh-20 f_p f_size_20 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
              Impulsa Tu Negocio con Nuestra Consultoría IT <br/>
              Especializada para PYMEs
              </h4>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="300px"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/security_7.gif"
                  alt="ciberseguridad"
                />
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  En el acelerado mundo de los negocios de hoy, la tecnología no es
                  solo una herramienta, sino un motor fundamental para el
                  <strong>crecimiento, la innovación y la eficiencia.</strong> Sin embargo, para las
                  pequeñas y medianas empresas, navegar por el complejo paisaje
                  tecnológico puede ser un desafío significativo. <strong>Ahí es donde
                      entramos nosotros.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  Nuestros Servicios Incluyen:
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Estrategia IT Personalizada:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Desarrollamos un plan estratégico que se alinea con los objetivos únicos de tu negocio y aborda tus desafíos específicos.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Optimización de Infraestructura:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Evaluamos y mejoramos tu infraestructura existente para asegurar que esté alineada con las mejores prácticas y tecnologías emergentes.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Gestión de la Seguridad IT:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Reforzamos tus defensas contra las amenazas cibernéticas y aseguramos la protección de tus datos críticos.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Capacitación y Soporte:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Equipamos a tu equipo con el conocimiento y las herramientas necesarias para aprovechar al máximo tus tecnologías.
                </p>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/consultoria-it.gif"
                  alt="infraestructura_landing"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s_features_section elegirnos">
        <div className="custom_container">
          <div className="row d-flex justify-content-center align-items-center text-center">
            <h1 className="titulos_servicios">¿Por Qué la Consultoría IT?</h1>
            <div className="col-md-8 d-flex justify-content-center align-items-center flex-column item">
              <article className="mt-5 mb-5 m-2 lh-18">
                  Muchas PYMEs luchan no solo con la selección e implementación
                  de las soluciones tecnológicas adecuadas sino también con la
                  <strong>optimización de sus recursos tecnológicos existentes.</strong> Nuestros
                  servicios de consultoría IT están diseñados para transformar esos
                  desafíos en <strong>oportunidades,</strong> permitiendo a tu negocio:
              </article>
          </div>
            <div className="s_features_item_card">
              <div className="card-MaC-SOFTINS wow fadeInLeft">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/niveles.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Alcanzar mayores niveles de eficiencia y productividad.</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                </article>
              </div>
              <div className="card-MaC-SOFTINS wow fadeInRight">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/it3.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Mejorar la seguridad y la resiliencia de tus sistemas.</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                </article>
              </div>
            </div>
            <div className="col-md-8 text-center mt-5">

            <h2 className="text-uppercase">Comprometidos con Tu Éxito</h2>

            <article className="lh-18">
                Nuestro equipo de expertos en IT está comprometido con el éxito
                de tu empresa. Con años de experiencia ayudando a PYMEs a
                crecer y prosperar a través de soluciones tecnológicas efectivas,
                entendemos lo que se necesita para convertir los retos en
                resultados tangibles.
            </article>
              <br />
            <h2 className="text-uppercase">¿Listo para Transformar Tu Negocio?</h2>

            <article className="lh-18">
                No permitas que los desafíos tecnológicos limiten el potencial de tu
                empresa. Contáctanos hoy para descubrir cómo nuestra
                consultoría IT puede poner a tu negocio en el camino rápido hacia
                el éxito.
            </article>
        </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default It;
