import React, { useState, useEffect } from "react";
const apiUrl = process.env.REACT_APP_API_URL;
const Planvps = () => {
  const [plans, setPlans] = useState([]); // Almacenar los datos de la API
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado de error

  // Estados para el buscador de dominios
  const [domain, setDomain] = useState(""); // Almacenar el valor del dominio ingresado
  const [domainStatus, setDomainStatus] = useState(null); // Estado del dominio (Activo/Inactivo)
  const [domainLoading, setDomainLoading] = useState(false); // Estado de carga de la búsqueda de dominio
  const [domainError, setDomainError] = useState(null); // Estado de error de la búsqueda de dominio

  useEffect(() => {
    // Consumir la API usando fetch
    fetch(apiUrl+"getCpanel") // Reemplaza con tu URL de API
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al obtener los datos"); // Control de errores
        }
        return response.json();
      })
      .then((data) => {
        setPlans(data); // Guardar los datos obtenidos de la API
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar el componente

  // Función para buscar el dominio
  const handleDomainSearch = () => {
    setDomainLoading(true);
    setDomainError(null);
    setDomainStatus(null);

    fetch(apiUrl+`checkdomain/${domain}`) // Reemplaza con la URL de tu API
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al verificar el dominio");
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          // Si hay un error en la respuesta, lo mostramos
          setDomainError(data.error);
        } else {
          // De lo contrario, actualizamos el estado con la información del dominio
          setDomainStatus(data.status);
        }
        setDomainLoading(false);
      })
      .catch((error) => {
        setDomainError(error.message);
        setDomainLoading(false);
      });
  };

  if (loading) {
    return <p>Cargando datos...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <section className="s_features_section_vps">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="box text-center col-lg-12 m-5 p-5 d-flex flex-column justify-content-center align-items-center">
              <h2
                className="titulos_servicios f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                Nuestros planes de VPS
              </h2>
              <br />
              <h4
                className="lh-20 f_p f_size_20 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                Potencia tu negocio con los mejores planes de servicios

                <div className="domain-search-container">
                  <input
                    type="text"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    placeholder="Ingrese el nombre de su dominio"
                  />
                  <button onClick={handleDomainSearch}><i className="fa fa-search"></i></button>

                </div>
                <br/>
                {domainLoading && <p>Cargando...</p>}
                {domainError && <p>Error: {domainError}</p>}
                {domainStatus && <p>Estado del Dominio: {domainStatus}</p>}
              </h4>
            </div>
          </div>
        </div>
      </section>

      <section className="s_features_section_vps">
        <div className="rowit separation">
          {/* Buscador de dominios */}


          {/* Listado de planes */}
          {plans.map((plan) => (
            <div key={plan.id} className="card">
              {/* Viñeta encima de la imagen */}
              <div className="ribbon"></div>

              <div className="car-img">
                <figure>
                  <img
                    loading="lazy"
                    decoding="async"
                    src="https://worldbus.ge/wp-content/uploads/2023/07/10-important-points-that-you-should-pay-attention-to-when-buying-a-cPanel-shared-hosting-1.jpg"
                    alt="infraestructura_landing"
                    className="plan-image"
                  />
                </figure>
              </div>

              {/* Precio del plan */}
              <div className="plan-price">
                <h3 className="price-text">{plan.monto} Bs./ Año
                </h3>
              </div>

              {/* Descripción y nombre del plan */}
              <div className="plan-details">
                <h2 className="titulos_servicios f_p f_size_20 f_700 t_color l_height45 wow fadeInRight">
                  {plan.nombre}
                </h2>
              </div>
              <div className="descripcion" dangerouslySetInnerHTML={{ __html: plan?.descripcion || '' }} />
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Planvps;
