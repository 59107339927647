import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;
const Vercat = () => {
  const { id } = useParams();
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const response = await axios.get(apiUrl+`getcatblogdatos/${id}`);
        setCategoryDetails(response.data);
      } catch (error) {
        setError('Error al obtener los detalles de la categoría');
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryDetails();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <section className="section blog-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-12 col-md-12 mb-5">
                  <div className="blog-item">
                    <div className="blog-thumb">
                      <img
                        src={categoryDetails?.img}
                        alt="Category"
                        className="img-fluid"
                      />
                    </div>
                    <div className="blog-item-content">
                      <h2 className="mt-3 mb-3">{categoryDetails?.nombre}</h2>
                      <div
                        className="mb-4"
                        dangerouslySetInnerHTML={{ __html: categoryDetails?.descripcion || '' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Sidebar */}
            <div className="col-lg-4">
              <div className="sidebar-wrap pl-lg-4 mt-5 mt-lg-0">
                <div className="sidebar-widget category mb-3">
                  <h5 className="mb-4">Categories</h5>
                  <ul className="list-unstyled">
                    {categoryDetails.catgeria.map((category, index) => (
                      <li className="align-items-center" key={category.id || index}>
                        <Link className="dropdown-item" to={`/vercatlat/${category.sql}`}>
                          {category.nombre}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section blog-wrap">
        <div className="container">
          <div className="rowcard">
            {categoryDetails.blogs.map((blog, index) => (
              <div className="card" key={blog.id || index}>
                <main>
                  <img src={blog?.img || 'default-image.jpg'} alt={blog?.nombre} />
                  <h6>{blog?.nombre || 'Phoebe Probatorem'}</h6>
                  <Link to={`/blog/${blog.sql}`} className="btn btn-primary">Ver más...</Link>
                </main>
                <div className="card-footer">
                  <div className="card-stat">
                    <i className="fa fa-eye"></i>
                    <span>{blog?.eye || '0'}</span> {/* Example for views */}
                  </div>
                  <div className="card-stat">
                    <i className="fa fa-thumbs-up"></i>
                    <span>{blog?.mg || '0'}</span> {/* Example for likes */}
                  </div>
                  <div className="card-stat">
                    <i className="fa fa-heart"></i>
                    <span>{blog?.me || '0'}</span> {/* Example for loves */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Vercat;
