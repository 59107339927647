import React from "react";
import Services from '../../components/Services';
const DesarrolloAP = () => {
  return (
    <>
      <section >
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="box text-center col-lg-12 m-5 p-5 d-flex flex-column justify-content-center align-items-center">
              <h2
                className="titulos_servicios f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                Nuestra area de Desarrolo de Aplicaciones
              </h2>
              <br />
              <h4
                className="lh-20 f_p f_size_20 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
              Potencia  tu negocio  con nuestros servicos de software comercial y privado
              </h4>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="300px"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/dev.gif"
                  alt="ciberseguridad"
                />
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  En <strong> M@C-SOFTINS, </strong> sabemos que la manera correcta de pocisionar  tu negocio es atraves de un ecosistema  a corde a tus requerimientos
                   <strong> Con más de 8 años de experiencia, </strong>
                   ofrecemos servicios confiables y seguros, diseñados para
                    <strong> maximizar la disponibilidad y el rendimiento de tus Clientes.</strong> trabajamos conlass mejores tecnologias que se acomodan a tu negocio
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="a_features_section_dev_server">
        <h2
          className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
          data-wow-delay="0.4s"
        >
          lENGUAJES MAS USADOS
        </h2>
        <div className="rowit separation">
            <div className="card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="80" height="80" src="https://rath.mac-softins.com/imgpublic/oficialpage/node.gif" alt="Servidores rack"/>
                    </figure>
                </div>
                <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Node js</p>
            </div>
            <div className="card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="80" height="80" src="https://rath.mac-softins.com/imgpublic/oficialpage/react.gif" alt="Servidores de torre"/>
                    </figure>
                </div>
                <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">React</p>
            </div>
            <div className=" card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="100" height="100" src="https://rath.mac-softins.com/imgpublic/oficialpage/php.gif" alt="Sistemas componibles"/>
                    </figure>
                </div>
                <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Php</p>
            </div>
            <div className="card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="100" height="100" src="https://rath.mac-softins.com/imgpublic/oficialpage/ionic.webp" alt="Sistemas hiperconvergentes"/>
                    </figure>
                </div>
                <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">
                <br/>
                Ionic</p>
            </div>
            <div className=" card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="80" height="80" src="https://rath.mac-softins.com/imgpublic/oficialpage/c.gif" alt="Infraestructura de blades"/>
                    </figure>
                </div>
                <p className=" psv" data-wow-delay="0.5s">C#</p>
            </div>
            <div className=" card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="80" height="80" src="https://rath.mac-softins.com/imgpublic/oficialpage/flutter.png" alt="Infraestructura de blades"/>
                    </figure>
                </div>
                <p className=" psv" data-wow-delay="0.5s">Flutter</p>
            </div>
            <div className=" card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="80" height="80" src="https://rath.mac-softins.com/imgpublic/oficialpage/python.gif" alt="Infraestructura de blades"/>
                    </figure>
                </div>
                <p className=" psv" data-wow-delay="0.5s">Python</p>
            </div>
        </div>
      </section>
      <section className="a_features_section_dev_server">
        <h2
          className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
          data-wow-delay="0.4s"
        >
          ALMACENAMIENTO DE INFORMACION
        </h2>
        <div className="rowit separation">
            <div className="card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="80" height="80" src="https://rath.mac-softins.com/imgpublic/oficialpage/Mongodb.gif" alt="Servidores rack"/>
                    </figure>
                </div>
                <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">mongoDB</p>
            </div>
            <div className="card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="80" height="80" src="https://rath.mac-softins.com/imgpublic/oficialpage/postgresql.gif" alt="Servidores de torre"/>
                    </figure>
                </div>
                <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Postgresql</p>
            </div>
            <div className=" card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="100" height="100" src="https://rath.mac-softins.com/imgpublic/oficialpage/mysql.png" alt="Sistemas componibles"/>
                    </figure>
                </div>
                <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s"> <br />
                Mysql</p>
            </div>
            <div className="card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="100" height="100" src="https://rath.mac-softins.com/imgpublic/oficialpage/redis.png" alt="Sistemas hiperconvergentes"/>
                    </figure>
                </div>
                <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Redis</p>
            </div>
            <div className=" card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="80" height="80"  src="https://rath.mac-softins.com/imgpublic/oficialpage/firebase.gif" alt="Infraestructura de blades"/>
                    </figure>
                </div>
                <p className=" psv" data-wow-delay="0.5s">Firebase</p>
            </div>
            <div className=" card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async"  width="100" height="100"  src="https://rath.mac-softins.com/imgpublic/oficialpage/sqlserver.jpg" alt="Infraestructura de blades"/>
                    </figure>
                </div>
                <p className=" psv" data-wow-delay="0.5s">SQLServer</p>
            </div>
            <div className=" card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="80" height="80"  src="https://rath.mac-softins.com/imgpublic/oficialpage/access.png" alt="Infraestructura de blades"/>
                    </figure>
                </div>
                <p className=" psv" data-wow-delay="0.5s">AccessDB
                </p>
            </div>
        </div>
      </section>
      <section className="a_features_section_dev">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  Nuestros Resultados
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Nuestros resulatdos  hablan por si al tener  varios clientes  que  dependen de nuestros ecosistemass  y plataformas nos convierten en tu mejor opcion a nivel de desarollo e impluso tecnologico
                </h3>


              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/ecosistema.gif"
                  alt="infraestructura_landing"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Services />
    </>
  );
};

export default DesarrolloAP;
