import React, { useState, useEffect } from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';
const Planvps = () => {
  const [plans, setPlans] = useState([]); // Almacenar los datos de la API
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado de error
  const apiUrl = process.env.REACT_APP_API_URL;
  // Estados para el buscador de dominios
  const [domain, setDomain] = useState(""); // Almacenar el valor del dominio ingresado
  const [domainStatus, setDomainStatus] = useState(null); // Estado del dominio (Activo/Inactivo)
  const [domainLoading, setDomainLoading] = useState(false); // Estado de carga de la búsqueda de dominio
  const [domainError, setDomainError] = useState(null); // Estado de error de la búsqueda de dominio
  const features = [
    {
      icon: "fa-flag", // Cambiado de "fa-flag-o"
      title: "Montado y produccion",
      description: "servicio y soporte de lenguajes de banguardia.",
      delay: "250ms",
    },
    {
      icon: "fa-rocket",
      title: "Discos SSD NVMe",
      description: "SSD NVMe y todos los niveles de RAID para más performance.",
      delay: "350ms",
    },
    {
      icon: "fa-shield-alt", // Cambiado de "fa-shield"
      title: "Seguro y confiable",
      description: "Aislamiento completo, uptime 99,9% y protección contra amenazas.",
      delay: "450ms",
    },
    {
      icon: "fa-medkit",
      title: "Soporte nivel extremo",
      description: "Delega en WNPower la gestión de tu servidor y relájate.",
      delay: "250ms",
    },
    {
      icon: "fa-laptop",
      title: "Sistemas Operativos",
      description: "Tenemos un montón de sistemas operativos. Windows inclusive.",
      delay: "350ms",
    },
    {
      icon: "fa-terminal",
      title: "Acceso root o administrador",
      description: "Te damos acceso root o administrador para que tengas todo el control.",
      delay: "450ms",
    },
    {
      icon: "fa-signal",
      title: "Recursos elásticos",
      description: "Nuestros Cloud VPS te permiten crecer vertical y horizontalmente.",
      delay: "250ms",
    },
    {
      icon: "fa-heart",
      title: "Los mejores proveedores",
      description: "Nos apoyamos en los mejores proveedores para tu tranquilidad.",
      delay: "350ms",
    },
    {
      icon: "fa-chart-bar", // Cambiado de "fa-bar-chart-o"
      title: "Estado del Servicio",
      description: "Cuentas con un panel web para conocer el estado de tus servicios en tiempo real.",
      delay: "450ms",
    },
  ];

  useEffect(() => {
    // Consumir la API usando fetch
    fetch(apiUrl+"getvps") // Reemplaza con tu URL de API
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al obtener los datos"); // Control de errores
        }
        return response.json();
      })
      .then((data) => {
        setPlans(data); // Guardar los datos obtenidos de la API
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar el componente

  // Función para buscar el dominio
  const handleDomainSearch = () => {
    setDomainLoading(true);
    setDomainError(null);
    setDomainStatus(null);

    fetch(apiUrl+`checkdomain/${domain}`) // Reemplaza con la URL de tu API
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al verificar el dominio");
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          // Si hay un error en la respuesta, lo mostramos
          setDomainError(data.error);
        } else {
          // De lo contrario, actualizamos el estado con la información del dominio
          setDomainStatus(data.status);
        }
        setDomainLoading(false);
      })
      .catch((error) => {
        setDomainError(error.message);
        setDomainLoading(false);
      });
  };

  if (loading) {
    return <p>Cargando datos...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <section className="s_features_section_vps">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="box text-center col-lg-12 m-5 p-5 d-flex flex-column justify-content-center align-items-center">
              <h2
                className="titulos_servicios f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
              Potencia Tu negocio con Nuestro mejores planes de servidores
              </h2>
              <br />
              <h4
                className="lh-20 f_p f_size_20 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                Potencia tu negocio con los mejores planes de servicios

                <div className="domain-search-container">
                  <input
                    type="text"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    placeholder="Ingrese el nombre de su dominio"
                  />
                  <button onClick={handleDomainSearch}><i className="fa fa-search"></i></button>

                </div>
                <br/>
                {domainLoading && <p>Cargando...</p>}
                {domainError && <p>Error: {domainError}</p>}
                {domainStatus && <p>Estado del Dominio: {domainStatus}</p>}
              </h4>
              <div className="Banner_img">
                    <img  src="https://rath.mac-softins.com/imgpublic/oficialpage/caracteristicas-hosting.png"/>
              </div>
            </div>
          </div>
          <div className="rowit separation">
              <div  className="card">
                <div className="ribbon"></div>
                <div className="car-img">
                  <figure>
                    <img
                      loading="lazy"
                      decoding="async"
                      src="https://rath.mac-softins.com/imgpublic/oficialpage/qnap.gif"
                      alt="infraestructura_landing"
                      className="plan-image"
                    />
                  </figure>
                </div>
                <div className="plan-price">
                  <h3 className="price-text"> VPS </h3>
                </div>
                <div className="plan-details">
                  <h2 className="titulos_servicios f_p f_size_20 f_700 t_color l_height45 wow fadeInRight">
                  </h2>
                </div>
              </div>
              <div  className="card">
                <div className="ribbon"></div>
                <div className="car-img">
                  <figure>
                    <img
                      loading="lazy"
                      decoding="async"
                      src="https://rath.mac-softins.com/imgpublic/oficialpage/qnap.gif"
                      alt="infraestructura_landing"
                      className="plan-image"
                    />
                  </figure>
                </div>
                <div className="plan-price">
                  <h3 className="price-text">Shared Hosting </h3>
                </div>
                <div className="plan-details">
                  <h2 className="titulos_servicios f_p f_size_20 f_700 t_color l_height45 wow fadeInRight">
                  </h2>
                </div>
              </div>
              <div  className="card">
                <div className="ribbon"></div>
                <div className="car-img">
                  <figure>
                    <img
                      loading="lazy"
                      decoding="async"
                      src="https://rath.mac-softins.com/imgpublic/oficialpage/qnap.gif"
                      alt="infraestructura_landing"
                      className="plan-image"
                    />
                  </figure>
                </div>
                <div className="plan-price">
                  <h3 className="price-text"> Cloud Hosting </h3>
                </div>
                <div className="plan-details">
                  <h2 className="titulos_servicios f_p f_size_20 f_700 t_color l_height45 wow fadeInRight">
                  </h2>
                </div>
              </div>
              <div  className="card">
                <div className="ribbon"></div>
                <div className="car-img">
                  <figure>
                    <img
                      loading="lazy"
                      decoding="async"
                      src="https://rath.mac-softins.com/imgpublic/oficialpage/qnap.gif"
                      alt="infraestructura_landing"
                      className="plan-image"
                    />
                  </figure>
                </div>
                <div className="plan-price">
                  <h3 className="price-text">Servicios en la Nube </h3>
                </div>
                <div className="plan-details">
                  <h2 className="titulos_servicios f_p f_size_20 f_700 t_color l_height45 wow fadeInRight">
                  </h2>
                </div>
              </div>
              <div  className="card">
                <div className="ribbon"></div>
                <div className="car-img">
                  <figure>
                    <img
                      loading="lazy"
                      decoding="async"
                      src="https://rath.mac-softins.com/imgpublic/oficialpage/qnap.gif"
                      alt="infraestructura_landing"
                      className="plan-image"
                    />
                  </figure>
                </div>
                <div className="plan-price">
                  <h3 className="price-text"> Servidores Dedicados </h3>
                </div>
                <div className="plan-details">
                  <h2 className="titulos_servicios f_p f_size_20 f_700 t_color l_height45 wow fadeInRight">
                  </h2>
                </div>
              </div>
          </div>
        </div>

      </section>
      <section id="features" className="feature-section">
           <div className="container">
             <h2 className="section-title">Todo lo que tus sitios o apps web necesitan</h2>
             <div className="row">
               {features.map((feature, index) => (
                 <div
                   className="col-md-4 col-sm-6 feature-box"
                   style={{ animationDelay: feature.delay }}
                   key={index}
                 >
                   <div className="feature-inner">
                     <i className={`fa ${feature.icon} feature-icon`}></i>
                     <h4 className="feature-title">{feature.title}</h4>
                     <p className="feature-description">{feature.description}</p>
                   </div>
                 </div>
               ))}
             </div>
           </div>
         </section>
    </>
  );
};

export default Planvps;
