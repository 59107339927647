import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;

const Planvps = () => {
  const [plans, setPlans] = useState([]); // Almacenar los datos de la API
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado de error

  // Estados para el buscador de dominios
  const [domain, setDomain] = useState(""); // Almacenar el valor del dominio ingresado
  const [domainStatus, setDomainStatus] = useState(null); // Estado del dominio (Activo/Inactivo)
  const [domainLoading, setDomainLoading] = useState(false); // Estado de carga de la búsqueda de dominio
  const [domainError, setDomainError] = useState(null); // Estado de error de la búsqueda de dominio

  useEffect(() => {
    // Consumir la API usando fetch
    fetch(`${apiUrl}getdatosview`) // Asegúrate de que la URL esté correctamente configurada
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al obtener los datos"); // Control de errores
        }
        return response.json();
      })
      .then((data) => {
        setPlans(data); // Guardar los datos obtenidos de la API
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar el componente

  // Función para buscar el dominio
  const handleDomainSearch = () => {
    setDomainLoading(true);
    setDomainError(null);
    setDomainStatus(null);

    fetch(`${apiUrl}checkdomain/${domain}`) // Asegúrate de que la URL esté correctamente configurada
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al verificar el dominio");
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          // Si hay un error en la respuesta, lo mostramos
          setDomainError(data.error);
        } else {
          // De lo contrario, actualizamos el estado con la información del dominio
          setDomainStatus(data.status);
        }
        setDomainLoading(false);
      })
      .catch((error) => {
        setDomainError(error.message);
        setDomainLoading(false);
      });
  };

  if (loading) {
    return <p>Cargando datos...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <section className="s_features_section_vps">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="box text-center col-lg-12 m-5 p-5 d-flex flex-column justify-content-center align-items-center">
              <h2
                className="titulos_servicios f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                Nuestros planes de VPS
              </h2>
              <br />
              <h4
                className="lh-20 f_p f_size_20 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                Potencia tu negocio con los mejores planes de servicios
              </h4>
              <div className="domain-search-container">
                <input
                  type="text"
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  placeholder="Ingrese el nombre de su dominio"
                />
                <button onClick={handleDomainSearch}>
                  <i className="fa fa-search"></i>
                </button>
              </div>
              <br />
              {domainLoading && <p>Cargando...</p>}
              {domainError && <p>Error: {domainError}</p>}
              {domainStatus && <p>Estado del Dominio: {domainStatus}</p>}
            </div>
          </div>
        </div>
      </section>

      <section className="s_features_section_vps">
        <div className="container">
          <div className="rowit separation">
            {/* Listado de planes */}
            {plans.map((plan, index) => (
              <div key={plan.id || index} className="card-serve">
                {/* Viñeta encima de la imagen */}
                <div className="ribbon"></div>

                <div className="car-img">
                  <figure>
                    <img
                      loading="lazy"
                      decoding="async"
                      src={plan.imagen_url || "https://rath.mac-softins.com/imgpublic/oficialpage/qnap.gif"}
                      alt="infraestructura_landing"
                      className="plan-image"
                    />
                  </figure>
                </div>
                <div className="plan-details">
                  <h2 className="titulos_servicios f_p f_size_20 f_700 t_color l_height45 wow fadeInRight">
                    {plan.tipo_con_espacio}
                  </h2>
                </div>

                <div className="descripcion">
                  <span className="badge">
                    {plan.total} planes disponibles
                  </span>
                  <br/>
                  <Link className="btn btn-main-2 k-link" id="btn-round-full-k1" to={`/viewdatacat/${plan.tipo_con_guion}`}>
                      <i className="fa fa-eye k-link"></i> Ver Planes
                  </Link>
                </div>

              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Planvps;
