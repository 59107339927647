import React, { useState, useEffect } from 'react';

import { FaPhone } from 'react-icons/fa'; // Usamos react-icons para el ícono de teléfono
import Swal from 'sweetalert2'; // Importa SweetAlert2
const apiUrl = process.env.REACT_APP_API_URL;
const Appointment = () => {
  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${year}-${month}-${day}`; // Formato requerido por el input tipo date
  };

  const [form, setForm] = useState({
    identi: '', // Añadido para almacenar el identificador
    department: '',
    doctor: '',
    date: getCurrentDate(), // Fecha actual por defecto
    email: '',
    name: '',
    phone: '',
    message: ''
  });

  const [areas, setAreas] = useState([]);
  const [preguntas, setPreguntas] = useState([]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const ticketData = {
      identi: form.identi,
      department: form.department,
      doctor: form.doctor,
      date: form.date,
      email: form.email,
      name: form.name,
      phone: form.phone,
      message: form.message
    };

    fetch(apiUrl+'savesoporteticket', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ticketData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Respuesta del servidor:', data);
        // Mostrar un mensaje de éxito usando SweetAlert2
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: 'Tu cita ha sido agendada exitosamente.',
          confirmButtonText: 'Aceptar',
        });
        // Resetear el formulario
        setForm({
          identi: form.identi,
          department: '',
          doctor: '',
          date: getCurrentDate(),
          email: '',
          name: '',
          phone: '',
          message: ''
        });
      })
      .catch((error) => {
        console.error('Error al enviar los datos:', error);
        // Mostrar un mensaje de error usando SweetAlert2
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un error al agendar tu cita. Por favor, intenta nuevamente.',
          confirmButtonText: 'Aceptar',
        });
      });
  };

  useEffect(() => {
    fetch(apiUrl+'sectionpagesoporte/360')
      .then((response) => response.json())
      .then((data) => {
        setAreas(data.areas);
        setPreguntas(data.preguntas);
        setForm((prevForm) => ({ ...prevForm, identi: data.identi }));
      })
      .catch((error) => {
        console.error('Error al obtener los datos de la API:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un error al cargar las áreas y consultas. Por favor, intenta nuevamente más tarde.',
          confirmButtonText: 'Aceptar',
        });
      });
  }, []);

  return (
    <section className="section appoinment">
      <div className="container">
        <div className="row align-items-center">
          {/* Imagen y teléfono */}
          <div className="col-lg-6">
            <div className="appoinment-content">
              <img
                src="https://rath.mac-softins.com/imgpublic/oficialpage/vr.gif"
                alt="Foco"
                className="img-fluid"
              />
              <div className="emergency">
                <h2 className="text-lg">
                  <FaPhone className="text-lg" /> +591 67916774
                </h2>
              </div>
            </div>
          </div>

          {/* Formulario de cita */}
          <div className="col-lg-6 col-md-10">
            <div className="appoinment-wrap mt-5 mt-lg-0">
              <h2 className="mb-2 title-color">Agenda Electrónica M@C</h2>
              <p className="mb-4">
                Regístrate en nuestra agenda electrónica y nosotros nos
                contactaremos contigo por medio de mensaje o llamada telefónica.
              </p>
              <form className="appoinment-form" onSubmit={handleSubmit}>
                <div className="row">
                  {/* Selección de Área */}
                  <div className="col-lg-6">
                    <div className="form-group">
                      <select
                        className="form-control"
                        name="department"
                        value={form.department}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Selecciona el Área</option>
                        {areas.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* Campo oculto para identi */}
                  <input type="hidden" name="identi" value={form.identi} />

                  {/* Selección de Consulta */}
                  <div className="col-lg-6">
                    <div className="form-group">
                      <select
                        className="form-control"
                        name="doctor"
                        value={form.doctor}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Seleccionar Consulta</option>
                        {preguntas.map((pregunta) => (
                          <option key={pregunta.id} value={pregunta.id}>
                            {pregunta.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* Campo de Fecha */}
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="date"
                        type="date"
                        className="form-control"
                        value={form.date}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  {/* Campo de Email */}
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="Correo Electrónico"
                        value={form.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  {/* Campo de Nombre */}
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Nombre Completo"
                        value={form.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  {/* Campo de Teléfono */}
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="phone"
                        type="tel"
                        className="form-control"
                        placeholder="Número de Teléfono"
                        value={form.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                {/* Campo de Mensaje */}
                <div className="form-group-2 mb-4">
                  <textarea
                    name="message"
                    className="form-control"
                    rows="6"
                    placeholder="Tu Mensaje"
                    value={form.message}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Botón de Envío */}
                <button type="submit" className="btn btn-main btn-round-full">
                Agenda tu proyecto ... <i className="icofont-simple-right ml-2"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Appointment;
