import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../../App.css';

const apiUrl = process.env.REACT_APP_API_URL;

const Blog = () => {
  const { id } = useParams();
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comment, setComment] = useState({ idBlog: id, nombre: '', email: '', web: '', comentario: '' });
  const [comments, setComments] = useState([]);
  const [eyeCount, setEyeCount] = useState(0); // Estado para contador de visitas
  const [likeCount, setLikeCount] = useState(0); // Estado para contador de Me gusta
  const [meencanatCount, setMeencanatCount] = useState(0); // Estado para contador de Me encanta

  // Función para obtener y actualizar el contador de visitas
  const fetchEyeCount = async () => {
    try {
      const response = await fetch(`${apiUrl}geteyeblog/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Error al obtener el contador de visitas');

      const data = await response.json(); // Capturar los datos de la respuesta
      if (data !== undefined) {
        setEyeCount(data); // Asignar contador de visitas desde la respuesta
      } else {
        throw new Error('La respuesta de la API no contiene "eye"');
      }
    } catch (error) {
      window.location.reload();
    }
  };

  // Función para obtener el contador de Me gusta
  const fetchLikeCount = async () => {
    try {
      const response = await fetch(`${apiUrl}getmegusta/${id}`);

      if (!response.ok) throw new Error('Error al obtener el contador de Me gusta');
      const data = await response.json();
      if (data !== undefined) {
        setLikeCount(data);
      } else {
        throw new Error('La respuesta de la API no contiene "mg"');
      }
    } catch (error) {
      window.location.reload();
    }
  };

  // Función para obtener el contador de Me encanta
  const fetchMeencantaCount = async () => {
    try {
      const response = await fetch(`${apiUrl}getencanta/${id}`);

      if (!response.ok) throw new Error('Error al obtener el contador de Me encanta');
      const data = await response.json();
      if (data !== undefined) {
        setMeencanatCount(data);
      } else {
        throw new Error('La respuesta de la API no contiene "encanta"');
      }
    } catch (error) {
      window.location.reload();
    }
  };

  // Función para obtener los detalles del blog
  const fetchCategoryDetails = async () => {
    try {
      const response = await fetch(`${apiUrl}getbloginfo/${id}`);
      if (!response.ok) throw new Error('Error al obtener los detalles del blog');
      const data = await response.json();
      setCategoryDetails(data);
      setComments(data.comentarios || []);
      setEyeCount(data.eye || 0); // Asignar contador de visitas desde la respuesta
      setLikeCount(data.mg || 0); // Asignar contador de Me gusta desde la respuesta
      setMeencanatCount(data.me || 0); // Asignar contador de Me encanta desde la respuesta
    } catch (error) {
      setError(error.message);
      window.location.reload();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Llamar a todas las funciones al montar el componente
    fetchCategoryDetails();
    fetchEyeCount();
    fetchLikeCount();
    fetchMeencantaCount();
  }, [id]);

  // Manejar cambios en los campos del formulario de comentarios
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setComment({ ...comment, [name]: value });
  };

  // Manejar el envío del formulario de comentarios
  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}addcomment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(comment),
      });
      if (!response.ok) throw new Error('Error al enviar el comentario');
      const newComment = await response.json();
      setComments([...comments, newComment]);
      setComment({ idBlog: id, nombre: '', email: '', web: '', comentario: '' });

      Swal.fire({
        icon: 'success',
        title: 'Comentario Enviado',
        text: 'Tu comentario ha sido enviado exitosamente a revisión ortográfica y sintaxis obscenas.',
      });
    } catch (error) {
      setError(error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message,
      });
    }
  };

  // Función para compartir en Facebook
  const handleFacebookShare = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
    window.open(facebookShareUrl, '_blank');
  };

  // Función para manejar el clic en "Me gusta"
  const handleLikeClick = async () => {
    try {
      const response = await fetch(`${apiUrl}getmegusta/${id}`, {
        method: 'GET',
      });
      if (!response.ok) throw new Error('Error al enviar el Me gusta');

      const data = await response.json();
      if (data !== undefined) {
        setLikeCount(data); // Actualizar el contador de Me gusta con los datos capturados
      } else {
        throw new Error('La respuesta de la API no contiene "mg"');
      }

      Swal.fire({
        icon: 'success',
        title: 'Me gusta Enviado',
        text: '¡Gracias por tu Me gusta!',
      });
    } catch (error) {
      window.location.reload();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message,
      });
    }
  };

  // Función para manejar el clic en "Me encanta"
  const handleMeencantaClick = async () => {
    try {
      const response = await fetch(`${apiUrl}getencanta/${id}`, {
        method: 'GET',
      });
      if (!response.ok) throw new Error('Error al enviar el "Me encanta"');

      const data = await response.json();
      if (data !== undefined) {
        setMeencanatCount(data); // Actualizar el contador de Me encanta con los datos capturados
      } else {
        throw new Error('La respuesta de la API no contiene "encanta"');
      }

      Swal.fire({
        icon: 'success',
        title: 'Me encanta Enviado',
        text: '¡Gracias por tu "Me encanta"!',
      });
    } catch (error) {
      window.location.reload();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message,
      });
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <section className="section blog-wrap">
        <div className="container">
          <div className="row">
            {/* Contenido Principal */}
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-12 col-md-12 mb-5">
                  <div className="blog-item">
                    {/* Imagen del Blog */}
                    <div className="blog-thumb">
                      <img
                        src={categoryDetails?.img}
                        alt="Blog"
                        className="img-fluid"
                      />
                    </div>
                    {/* Contenido del Blog */}
                    <div className="blog-item-content">
                      <h2 className="mt-3 mb-3">{categoryDetails?.nombre}</h2>
                      <div
                        className="mb-4"
                        dangerouslySetInnerHTML={{ __html: categoryDetails?.descripcion || '' }}
                      />
                    </div>

                    {/* Botones de estadísticas */}
                    <div className="card-footer">
                      {/* Contador de Visitas */}
                      <div className="card-stat">
                        <button className="btn btn-primary-blog" onClick={fetchEyeCount}>
                          <i className="fa fa-eye"></i>
                          <span>{eyeCount}</span>
                        </button>
                      </div>
                      {/* Botón de Me gusta */}
                      <div className="card-stat">
                        <button className="btn btn-primary-blog" onClick={handleLikeClick}>
                          <i className="fa fa-thumbs-up color_i"></i>
                          <span>{likeCount}</span>
                        </button>
                      </div>
                      {/* Botón de Me encanta */}
                      <div className="card-stat">
                        <button className="btn btn-primary-blog" onClick={handleMeencantaClick}>
                          <i className="fa fa-heart"></i>
                          <span>{meencanatCount}</span>
                        </button>
                      </div>
                    </div>

                    {/* Botones para compartir en redes sociales */}
                    <div className="s_blog_social">
                      <h3>Comparte este post con tus amigos</h3>
                      <ul>
                        <li>
                          <button className="btn btn-primary-blog" onClick={handleFacebookShare}>
                            <i className="fa fa-share-alt"></i> Facebook
                          </button>
                        </li>
                        <li>
                          <a
                            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(window.location.href)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa-brands fa-twitter"></i> Twitter
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa-brands fa-linkedin-in"></i> LinkedIn
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://api.whatsapp.com/send?text=Visita%20el%20blog%20en%20${encodeURIComponent(window.location.href)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa-brands fa-whatsapp"></i> WhatsApp
                          </a>
                        </li>
                      </ul>
                    </div>

                    {/* Sección de Comentarios */}
                    <div className="comments-section">
                      <h3>Comentarios</h3>
                      {comments.length === 0 ? (
                        <p>No hay comentarios todavía.</p>
                      ) : (
                        comments.map((c, index) => (
                          <div key={index} className="comment">
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAswJKNKcHG8dxDXOSnQ6cAdOeGRiIcZzkUg&s"
                              alt={`${c.nombre}'s avatar`}
                              className="comment-avatar"
                            />
                            <div className="comment-content">
                              <a href={c.web} className="comment-name">
                                {c.nombre}
                              </a>
                              <p className="comment-text">{c.comentario}</p>
                            </div>
                          </div>
                        ))
                      )}
                    </div>

                    {/* Formulario para añadir un comentario */}
                    <div className="comment-form">
                      <h3>Añadir Comentario</h3>
                      <form onSubmit={handleCommentSubmit}>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="nombre"
                            placeholder="Nombre"
                            value={comment.nombre}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Correo Electrónico"
                            value={comment.email}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="url"
                            className="form-control"
                            name="web"
                            placeholder="Sitio Web (opcional)"
                            value={comment.web}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            name="comentario"
                            rows="5"
                            placeholder="Escribe tu comentario aquí..."
                            value={comment.comentario}
                            onChange={handleInputChange}
                            required
                          ></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Enviar Comentario
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Sidebar */}
            <div className="col-lg-4 col-static">
              <div className="sidebar-wrap pl-lg-4 mt-5 mt-lg-0">
                <div className="sidebar-widget category mb-3">
                  <h5 className="mb-4">Categorías</h5>
                  <ul className="list-unstyled">
                    {categoryDetails?.catgeria.map((category, index) => (
                      <li className="align-items-center" key={index}>
                        <Link className="dropdown-item" to={`/vercatlat/${category.sql}`}>
                          {category.nombre}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
