import React, { useEffect } from 'react';

const Portafolio = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Aquí puedes manejar los eventos de teclado si es necesario
      event.preventDefault(); // Previene el comportamiento de las teclas en la página principal
    };

    // Agregar el manejador de eventos cuando el componente se monta
    window.addEventListener('keydown', handleKeyDown);

    // Limpiar el manejador de eventos cuando el componente se desmonta
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <section style={{ width: '100%', height: '100vh' }}>
      <iframe
        src="https://oficina.mac-softins.com/"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        title="Portafolio"

      />
    </section>
  );
};

export default Portafolio;
