import React from "react";

const Polis = () => {
  return (
    <>
      <section className="s_features_section" style={{ marginTop: "100px" }}>
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="box text-center col-lg-12 m-5 p-5 d-flex flex-column justify-content-center align-items-center">
              <h2
                className="titulos_servicios f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                PÓLIZA DE SOPORTE IT
              </h2>
              <br />
              <h4
                className="lh-20 f_p f_size_20 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
              Eleva el Rendimiento de Tu Empresa <br />
              con Nuestras Pólizas de Soporte IT para PYMEs
              </h4>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="400px"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/security_3.gif"
                  alt="ciberseguridad"
                />
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >

                      En el entorno empresarial actual, donde la tecnología es el pilar de
                      casi todas las operaciones, un solo contratiempo IT puede significar
                      horas de inactividad, pérdida de ingresos y, lo más importante,
                      insatisfacción del cliente. Por eso, <strong>ofrecemos pólizas de soporte IT</strong>
                      diseñadas específicamente para las necesidades de las <strong>pequeñas
                      y medianas empresas,</strong>permitiéndote concentrarte en lo que mejor sabes
                      hacer: dirigir tu negocio.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  ¿Qué Incluyen Nuestras <br />
                   Pólizas de Soporte IT?
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Soporte Técnico Ilimitado:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Acceso a nuestro equipo de expertos IT para solucionar cualquier problema técnico, ya sea de forma remota o in situ.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Mantenimiento Preventivo:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Programamos revisiones regulares de tu infraestructura IT para prevenir problemas antes de que ocurran.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Monitoreo Continuo:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Vigilamos tus sistemas 24/7 para detectar y resolver problemas de forma proactiva, minimizando el tiempo de inactividad.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Respuesta Rápida a Incidentes:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Garantizamos tiempos de respuesta rápidos para que tu negocio vuelva a estar operativo lo antes posible.
                </p>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/poliza.gif"
                  alt="infraestructura_landing"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s_features_section elegirnos">
        <div className="custom_container">
          <div className="row d-flex justify-content-center align-items-center text-center">
            <h1 className="titulos_servicios">Beneficios de Nuestras Pólizas de Soporte IT</h1>
            <div className="s_features_item_card">
              <div className="card-MaC-SOFTINS wow fadeInLeft">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/tranquilidad.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Tranquilidad</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                  Saber que tu infraestructura IT está en manos de expertos te permite enfocarte en tu negocio.
                </article>
              </div>
              <div className="card-MaC-SOFTINS wow fadeInRight">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/costoefectividad.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Costo-Efectividad</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                  Nuestras pólizas eliminan la necesidad de un departamento IT interno costoso, ofreciendo un servicio de alta calidad a una fracción del costo.
                </article>
              </div>
            </div>
            <div className="col-md-8 text-center mt-5">
                <h2 className="text-uppercase">¿Por Qué Elegirnos?</h2>
                <article className="lh-18">
                    Nuestro compromiso es con la excelencia y la satisfacción del
                    cliente. Con años de experiencia brindando <strong>soporte IT a PYMEs,</strong>
                    entendemos las necesidades únicas de tu negocio y cómo <strong>la
                        tecnología puede servir mejor a esos objetivos.</strong>
                </article>
                <br />
                <article className="lh-18">
                    <strong>¡No Dejes que los Problemas IT Detengan Tu Negocio! <br /></strong>
                    Contacta con nosotros hoy para explorar cómo nuestras pólizas de...
                </article>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default Polis;
