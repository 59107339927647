import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de importar 'Link' si estás usando react-router-dom
const apiUrl = process.env.REACT_APP_API_URL;

const Noticias = () => {
  const [noticias, setNoticias] = useState([]);
  const [areas, setAreas] = useState([]); // Estado para áreas
  const [urlimg, setUrlimg] = useState(''); // Estado para la URL base de las imágenes

  useEffect(() => {
    // Consumir la API para obtener las noticias y áreas
    const fetchNoticias = async () => {
      try {
        const response = await fetch(apiUrl + 'getnoticias');
        const data = await response.json();
        setNoticias(data.noticias); // Actualizar el estado con las noticias obtenidas
        setAreas(data.areas); // Actualizar el estado con las áreas obtenidas
        setUrlimg(data.url); // Actualizar el estado con la URL base de las imágenes
      } catch (error) {
        console.error('Error al consumir la API:', error);
      }
    };

    fetchNoticias();
  }, []);

  return (
    <div>
      <section className="section blog-wrap">
        <div className="container">
          <div className="row">
            {/* Sección de Noticias (8 columnas) */}
            <div className="col-lg-8 col-md-8">
              <h1>Noticias</h1>
              <div className="page-content">
                {noticias.length > 0 ? (
                  noticias.map((noticia) => (
                    <Link to={`/Viewnoticia/${noticia.id}`} key={noticia.id}>
                      <div className="card-noticia">
                        <div className="content">
                          <div className="title">
                            {noticia.name}
                          </div>
                          {urlimg && (
                            <img
                              src={`${urlimg}/${noticia.url}`} // Construir la URL de la imagen
                              alt={noticia.name}
                              className="img-fluid-l" // Asegúrate de que la imagen sea responsiva
                            />
                          )}
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <p>No hay noticias disponibles</p>
                )}
              </div>
            </div>

            {/* Sección de Categorías (4 columnas) */}
            <div className="col-lg-4 col-md-4">
              <div className="sidebar-wrap pl-lg-4 mt-5 mt-lg-0">
                <div className="sidebar-widget category mb-3">
                  <h5 className="mb-4">Áreas De Nuestros Logros</h5>
                  <ul className="list-unstyled">
                    {areas.length > 0 ? (
                      areas.map((area, index) => (
                        <li className="align-items-center" key={index}>
                          <Link className="dropdown-item" to={`/verareanoticias/${area.id}`}>
                            {area.name}
                          </Link>
                        </li>
                      ))
                    ) : (
                      <p>No hay áreas disponibles</p>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Noticias;
