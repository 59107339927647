// src/components/Header.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext'; // Importa el contexto

const apiUrl = process.env.REACT_APP_API_URL;

const Header = () => {
  const [weather, setWeather] = useState(null);
  const [date, setDate] = useState('');
  const [isSticky, setIsSticky] = useState(false);
  const [categories, setCategories] = useState([]);

  // Accede al contexto global para actualizar las estadísticas
  const { setEstadisticas } = useContext(GlobalContext);

  useEffect(() => {
    // Obtener la fecha actual
    const today = new Date();
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    setDate(today.toLocaleDateString('es-ES', options));

    // Obtener el clima actual
    const fetchWeather = async () => {
      try {
        const response = await axios.get('https://api.openweathermap.org/data/2.5/weather', {
          params: {
            q: 'Potosí,BO',
            appid: 'e1749ff40f9a5a3b52a2b5f6123f7843',
            units: 'metric',
            lang: 'es',
          },
        });
        setWeather(response.data);
      } catch (error) {
        console.error('Error fetching weather data', error);
      }
    };

    // Llamada a la API para incrementar el contador de visitas
    const incrementStatistics = async () => {
      try {
        const response = await axios.get('https://swserviceapp.mac-softins.com/api/incrementestaadisticovistas');
        setEstadisticas(response.data); // Actualiza el estado global con las estadísticas obtenidas
        localStorage.setItem('estadisticas', JSON.stringify(response.data)); // Guarda el dato en Local Storage
      } catch (error) {
        console.error('Error incrementing statistics', error);

        // Opcional: Recuperar estadísticas del Local Storage si la API falla
        const storedEstadisticas = localStorage.getItem('estadisticas');
        if (storedEstadisticas) {
          setEstadisticas(JSON.parse(storedEstadisticas));
        }
      }
    };

    // Llamada a la API para obtener las categorías
    const fetchCategories = async () => {
      try {
        const response = await axios.get(apiUrl + 'getcatname');
        setCategories(response.data); // Asumiendo que la API devuelve un array de categorías
      } catch (error) {
        console.error('Error fetching categories', error);
      }
    };

    fetchWeather();
    fetchCategories();
    incrementStatistics(); // Incrementa estadísticas al cargar la página

    // Manejo del scroll
    const handleScroll = () => {
      const navbar = document.getElementById('navbar');
      if (!navbar) return; // Asegúrate de que el elemento existe

      const stickyPoint = navbar.offsetTop;

      if (window.pageYOffset > stickyPoint) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [apiUrl, setEstadisticas]);

  return (
    <header>

      <nav className={`navbar navbar-expand-lg navigation ${isSticky ? 'sticky' : ''}`} id="navbar">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Logo" className="img-fluid-h" />
          </a>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarmain"
            aria-controls="navbarmain"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icofont-navigation-menu"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarmain">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/service-empresarial">Servicios</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/noticias">Noticias</Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="dropdown05"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  M @ C - S O F T I N S <i className="icofont-thin-down"></i>
                </a>
                <ul className="dropdown-menu" aria-labelledby="dropdown05">
                  <li><Link className="dropdown-item" to="/historia">Historia</Link></li>
                  <li><Link className="dropdown-item" to="/InfraestructuraTI">Infraestructura <br /> Tecnologica</Link></li>
                  <li><Link className="dropdown-item" to="/DesarrolloApp">Desarrollo y <br /> Aplicaciones</Link></li>
                  <li><Link className="dropdown-item" to="/portafolio">Portafolio <br /> Empresarial</Link></li>
                  <li><Link className="dropdown-item" to="/vpshost">Servicios De <br /> Hosting y VPS</Link></li>
                  <li><Link className="dropdown-item" to="/Venta-Importacion">Venta <br />Importacion IT </Link></li>
                  <div className="divider mb-4"></div>
                  <li><Link className="dropdown-item" to="/Portal360">VR-0rbit360°</Link></li>
                  <li><Link className="dropdown-item" to="/construccion">FAE-M@C</Link></li>
                  <li><Link className="dropdown-item" to="/construccion">M@C-IPTV</Link></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="dropdown06"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Catálogo de Clouding <i className="icofont-thin-down"></i>
                </a>
                <ul className="dropdown-menu" aria-labelledby="dropdown06">
                  <li><Link className="dropdown-item" to="/Catalogocloud">Catálogo</Link></li>
                  <li><Link className="dropdown-item" to="/paypal">Paga con PayPal</Link></li>
                  <li><Link className="dropdown-item" to="/QRpayment">Pagos De Asesoría QR</Link></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="dropdown07"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Blog <i className="icofont-thin-down"></i>
                </a>
                <ul className="dropdown-menu" aria-labelledby="dropdown07">
                  {categories.map((category, index) => (
                    <li key={index}>
                      <Link className="dropdown-item" to={`/vercat/${category.sql}`}>{category.nombre}</Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact"><i className="fa fa-volume-control-phone"></i></Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
