import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2'; // Asegúrate de instalar SweetAlert2

const apiUrl = process.env.REACT_APP_API_URL;

const ShoppingCartForm = ({
  requestedProducts = [],
  contactInfo = {},
  handleInputChange,
  closeModal,
  setRequestedProducts,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  // Validación del formulario
  const validateForm = () => {
    const { name, email, phone, empresa, pais, departamento } = contactInfo;

    if (!name || !email || !phone || !empresa || !pais || !departamento) {
      Swal.fire({
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Por favor, completa todos los campos antes de enviar el formulario.',
      });
      return false;
    }

    if (requestedProducts.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Sin productos',
        text: 'Por favor, agrega al menos un producto a la solicitud.',
      });
      return false;
    }

    for (let product of requestedProducts) {
      if (!product.nombre || !product.quantity || product.quantity <= 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Producto inválido',
          text: 'Asegúrate de que todos los productos tengan un nombre y cantidad válida.',
        });
        return false;
      }
    }

    return true;
  };

  // Envío del formulario
  const submitForm = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    const data = {
      requestedProducts,
      contactInfo,
    };

    try {
      const response = await fetch(`${apiUrl}getcotizacionespage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }

      await response.json();
      closeModal();
      setRequestedProducts([]);
      Swal.fire({
        icon: 'success',
        title: 'Solicitud enviada con éxito',
        text: 'Hemos recibido tu solicitud de cotización.',
      });
    } catch (error) {
      console.error('Error:', error);

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al enviar la solicitud. Inténtalo de nuevo.',
      });
    } finally {
      setLoading(false);
    }
  };

  const goToNextStep = () => {
    if (currentStep < 4) setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  // Función para eliminar un producto de la solicitud
  const removeItem = (index) => {
    const updatedProducts = requestedProducts.filter((_, i) => i !== index);
    setRequestedProducts(updatedProducts);

    // Cerrar el modal si no hay productos
    if (updatedProducts.length === 0) {
      closeModal();
    }
  };

  return (
    <>
      <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={submitForm} className="p-12 rounded">
        <center>
          <h5>Solicitud De Cotización</h5>
        </center>
        <div id="wizard" className="form_wizard wizard_horizontal">
          <ul className="wizard_steps anchor">
            {[1, 2, 3, 4].map((step) => (
              <li key={step}>
                <a href={`#step-${step}`} className={currentStep === step ? 'selected' : 'done'}>
                  <span className="step_no">{step}</span>
                </a>
              </li>
            ))}
          </ul>

          <div className="stepContainer">
            {currentStep === 1 && (
              <div id="step-1" className="content">
                <div className="container300">
                  <table>
                    <thead className="tabla_cabeccera">
                      <tr>
                        <th>Nombre del artículo</th>
                        <th>Cantidad</th>
                        <th><i className="fa fa-cog"></i></th>
                      </tr>
                    </thead>
                    <tbody className="with-text">
                      {requestedProducts.map((product, index) => (
                        <tr key={index}>
                          <td style={{ width: '80%' }}>{product.nombre}</td>
                          <td style={{ width: '20%' }}>
                            <input
                              className="form-control-k-m"
                              type="number"
                              min="1"
                              value={product.quantity || 1}
                              onChange={(e) => {
                                const newQuantity = Math.max(1, e.target.value);
                                const updatedProducts = [...requestedProducts];
                                updatedProducts[index].quantity = newQuantity;
                                setRequestedProducts(updatedProducts); // Actualizamos el estado de los productos
                              }}
                            />
                          </td>
                          <td>
                            <a onClick={() => removeItem(index)}><i className="fa fa-trash"></i></a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {currentStep === 2 && (
              <div id="step-2" className="content">
                <h2 className="StepTitle">Información de Contacto</h2>
                <div className="mb-3">
                  <label className="form-label">Nombre:</label>
                  <input
                    type="text"
                    name="name"
                    value={contactInfo.name || ''}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Correo electrónico:</label>
                  <input
                    type="email"
                    name="email"
                    value={contactInfo.email || ''}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Teléfono:</label>
                  <input
                    type="tel"
                    name="phone"
                    value={contactInfo.phone || ''}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
              </div>
            )}

            {currentStep === 3 && (
              <div id="step-3" className="content">
                <h2 className="StepTitle">Información Regional</h2>
                <div className="mb-3">
                  <label className="form-label">Empresa:</label>
                  <input
                    type="text"
                    name="empresa"
                    value={contactInfo.empresa || ''}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">País:</label>
                  <input
                    type="text"
                    name="pais"
                    value={contactInfo.pais || ''}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Departamento:</label>
                  <input
                    type="text"
                    name="departamento"
                    value={contactInfo.departamento || ''}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
              </div>
            )}

            {currentStep === 4 && (
              <div id="step-4" className="content resolucion">
                <h2 className="StepTitle">Declaración de Datos</h2>
                <p>
                  Asegúrate de que toda la información proporcionada sea correcta antes de enviar la solicitud ya que nuestro equipo de ventas se comunicara a la informacion brindada.
                </p>
                <button type="submit" className="btn btn-primary">
                  Solicitar Cotización
                </button>
              </div>
            )}
          </div>

          <div className="actionBar">
            <button type="button" className="btn btn-primary" onClick={goToPreviousStep} disabled={currentStep === 1}>
              Anterior
            </button>
            <button type="button" className="btn btn-success" onClick={goToNextStep} disabled={currentStep === 4}>
              Siguiente
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

ShoppingCartForm.propTypes = {
  requestedProducts: PropTypes.array.isRequired,
  contactInfo: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  setRequestedProducts: PropTypes.func.isRequired,
};

export default ShoppingCartForm;
