import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import ShoppingCartForm from './componentes/ShoppingCartForm'; // Importar ShoppingCartForm

const apiUrl = process.env.REACT_APP_API_URL;

const Viit = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryData, setSelectedCategoryData] = useState([]);
  const [selectedProductlistData, setSelectedProductlistData] = useState([]);
  const [selectedProductlistDataShow, setselectedProductlistDataShow] = useState([]);
  const [requestedProducts, setRequestedProducts] = useState([]);
  const [contactInfo, setContactInfo] = useState({ name: '', email: '', phone: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // Nuevo estado para controlar la visibilidad del modal
  const [selectedProduct, setSelectedProduct] = useState(null);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(apiUrl + 'getcategroiait');
        if (!response.ok) throw new Error('Error al obtener las categorías');
        const data = await response.json();
        setCategories(data);
        setRequestedProducts([]);
        setSelectedProductlistData([]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = async (categoryId) => {
    try {
      const response = await fetch(apiUrl + `getcate_area_it/${categoryId}`);
      if (!response.ok) throw new Error('Error al obtener los detalles de la categoría');
      const data = await response.json();
      setSelectedProductlistData([]);
      setSelectedCategoryData(data);
      setSelectedProduct(null);
      setselectedProductlistDataShow([])
    } catch (error) {
      console.error(error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleProductClick = (productId) => {
    const endpoint = `https://swserviceapp.mac-softins.com/api/sectionshowproduct/${productId}`;

    fetch(endpoint)
      .then(response => {
        if (!response.ok) {
          throw new Error('Error en la solicitud del producto');
        }
        return response.json();
      })
      .then(data => {
        setSelectedProduct(data["productos"]["0"]); // Actualiza el estado con los detalles del producto
        setSelectedCategoryData([]);
        setSelectedProductlistData([]);
        setselectedProductlistDataShow(data["aleatorio"])
      })
      .catch(error => {
        console.error('Error al obtener el producto:', error);
      });
  };

  const handleCardClick = async (itemId) => {
    try {
      const response = await fetch(apiUrl + `productolist/${itemId}`);
      if (!response.ok) throw new Error('Error al obtener los detalles del producto');
      const data = await response.json();
      setSelectedCategoryData([]);
      setSelectedProductlistData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const addToCart = (item) => {
    setRequestedProducts((prev) => {
      const existingProduct = prev.find((product) => product.id === item.id);
      if (existingProduct) {
        return prev.map((product) =>
          product.id === item.id
            ? { ...product, quantity: product.quantity + 1 }
            : product
        );
      } else {
        return [...prev, { ...item, quantity: 1 }];
      }
    });

    Swal.fire({
      icon: 'success',
      title: '¡Producto agregado!',
      text: `${item.nombre} ha sido añadido a tu carrito.`,
      confirmButtonText: 'Aceptar',
    });

    // Puedes elegir si deseas abrir el modal aquí o no
    // setIsModalOpen(true); // Si quieres abrir el modal al agregar un producto
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire('¡Éxito!', 'Tus datos han sido enviados.', 'success');
    setRequestedProducts([]); // Limpiar el carrito después de enviar
    setIsModalOpen(false); // Cerrar el modal después de enviar
  };

  // Nueva función para buscar productos
  const searchProducts = async (term) => {
    if (!term) {
      setSelectedProductlistData([]); // Limpiar resultados si no hay término
      return;
    }

    try {
      const response = await fetch(apiUrl + `searchproducto/${term}`);
      if (!response.ok) throw new Error('Error al obtener los productos');
      const data = await response.json();
      setSelectedProductlistData(data); // Actualizar el estado con los resultados de la búsqueda
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Llama a la función de búsqueda cada vez que el término de búsqueda cambie
    searchProducts(searchTerm);
  }, [searchTerm]);

  return (
    <div className="container">
      <div className="search-container">
        <input
          type="text"
          placeholder="Buscar productos..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <button className="search-button">
          <i className="fa fa-search"></i>
        </button>
        <button
              className="search-card"
              onClick={() => requestedProducts.length > 0 && setIsModalOpen(true)} // Solo abrir el modal si hay productos en el carrito
            >
              <i className="fas fa-shopping-cart"></i>
              <span className="badge-success">{requestedProducts.length}</span>
            </button>
      </div>

      {/* Modal para el ShoppingCartForm */}
      {isModalOpen && (
        <div className="modal-overlay">

          <div className="modal-content">

            <ShoppingCartForm
              requestedProducts={requestedProducts}
              contactInfo={contactInfo}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              closeModal={() => setIsModalOpen(false)} // Cerrar el modal
              setRequestedProducts={setRequestedProducts} // Pasamos la función al componente hijo
            />
          </div>
        </div>
      )}

      <section className="color-1">
        <nav id="nav" className={`cl-effect-1 ${isMenuOpen ? 'open' : ''}`}>
          {categories.map((category) => (
            <a
              key={category.id}
              href="#"
              onClick={() => handleCategoryClick(category.area)}
            >
              <span data-hover={category.area}>{category.area}</span>
            </a>
          ))}
        </nav>
      </section>

      <div className="divider mb-4"></div>

      {selectedCategoryData.length > 0 && (
        <div className="category-cards">
          <div className="card-grid">
            {selectedCategoryData.map((item) => (
              <div key={item.id} className="category-card" onClick={() => handleCardClick(item.id)}>
                <div className="card-content">
                  <img className="imgCard" src={item.img} alt={item.nombre} />
                  <p>{item.nombre}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedProductlistData.length > 0 && (
        <div className="category-cards_produc">
          <div className="card-grid">
            {selectedProductlistData.map((item) => (
              <div key={item.id} className="category-card-pro">
                <div className="card-content">
                  <div className="image-container">
                    <img className="imgCard" src={item.img} alt={item.nombre} />
                    <button
                      className="view-details-button"
                      onClick={() => handleProductClick(item.id)}
                    >
                      <i className="fa fa-eye"></i>
                    </button>
                  </div>
                  <p>{item.nombre}</p>
                  <span className="badge-marca">{item.marca}</span>
                  <button className="add-to-cart-button" onClick={() => addToCart(item)}>
                    <i className="fas fa-shopping-cart"></i> Añadir al carrito
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="container">
        {selectedProduct ? (
          <div className="product-details-grid">
            {/* Columna de imagen (4 columnas) */}
            <div className="imgedetail col-6">
              <img src={selectedProduct.img} alt={selectedProduct.nombre} />
            </div>

            {/* Columna de detalles (8 columnas) */}
            <div className="product-info col-6">
              <h5>{selectedProduct.nombre}</h5>
              <span className="badge-marca">{selectedProduct.marca} </span>
              <div
                className="mb-4"
                dangerouslySetInnerHTML={{ __html: selectedProduct?.descripcion || '' }}
              />
              <button className="add-to-cart-buttonshow" onClick={() => addToCart(selectedProduct)}>
                <i className="fas fa-shopping-cart"></i> Añadir al carrito
              </button>


            </div>

          </div>
        ) : (
          <>
            {/* Lista de categorías y productos */}
            <div className="category-cards_produc">
              <div className="card-grid">
                {selectedProductlistDataShow.map((item) => (
                  <div key={item.id} className="category-card-pro">
                    <div className="card-content">
                      <div className="image-container">
                        <img className="imgCard" src={item.img} alt={item.nombre} />
                        <button
                          className="view-details-button"
                          onClick={() => handleProductClick(item.id)}
                        >
                          <i className="fa fa-eye"></i>
                        </button>
                      </div>
                      <p>{item.nombre}</p>
                      <span className="badge-marca">{item.marca}</span>
                      <button className="add-to-cart-button" onClick={() => addToCart(item)}>
                        <i className="fas fa-shopping-cart"></i> Añadir al carrito
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        {/* Aquí se muestran las tarjetas de productos siempre, debajo del detalle */}

        <div className="product-list-cards">
          <h3>Productos Relacionados</h3>
          <div className="card-grid">
            {selectedProductlistDataShow.map((item) => (
              <div key={item.id} className="category-card-pro">
                <div className="card-content">
                  <div className="image-container">
                    <img className="imgCardshow" src={item.img} alt={item.nombre} />
                    <button
                      className="view-details-button"
                      onClick={() => handleProductClick(item.id)}
                    >
                      <i className="fa fa-eye"></i>
                    </button>
                  </div>
                  <p>{item.nombre}</p>
                  <span className="badge-marca">{item.marca}</span>
                  <button className="add-to-cart-button" onClick={() => addToCart(item)}>
                    <i className="fas fa-shopping-cart"></i> Añadir al carrito
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viit;
