import React from 'react';
const MisionVision = () => {
  return (
    <div className="mision-vision-page">
    <h5 className="h5mv">
    En M@C-SOFTINS, somos tus aliados tecnológicos de confianza. <br />
    Con 10 años de experiencia, estamos aquí para simplificar y potenciar tus soluciones digitales.<br />
    Descubre cómo podemos ayudarte a triunfar en el mundo tecnológico.
    </h5>
      <section className="more-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
              style={{ width: '100%' }}
                src="https://rath.mac-softins.com/imgpublic/oficialpage/sobrenosotros.svg" // Asegúrate de que la ruta sea correcta
                alt="more"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6">
              <div className="item d-flex justify-content-between align-items-center">
                <img
                  style={{ width: '10%' }}
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/SN%20mision.gif" // Asegúrate de que la ruta sea correcta
                  alt="more"
                  className="img-fluid"
                />
                <div className="cont">
                  <h3 className="h3mv">Nuestra Misión: Simplificar la Tecnología para Tu Negocio</h3>
                  <p className="pmv">
                    En M@C-SOFTINS, estamos en una misión constante de hacer que la tecnología sea
                    simple y efectiva para ti y tu empresa. Con dos décadas de experiencia y una
                    pasión incansable por la innovación y la atención personalizada, nuestro
                    objetivo es simplificar tus soluciones tecnológicas.
                  </p>
                </div>
              </div>
              <div className="item d-flex justify-content-between align-items-center">
                <img
                  style={{ width: '10%' }}
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/SN%20mision.gif" // Asegúrate de que la ruta sea correcta
                  alt="more"
                  className="img-fluid"
                />
                <div className="cont">
                  <h3 className="h3mv">Nuestra Visión: Simplificar la Tecnología para Tu Negocio</h3>
                  <p className="pmv">
                    En M@C-SOFTINS, Para el futuro, nos comprometemos a sumar todos nuestros esfuerzos para lograr automatizar
                    como una empresa líder en el ramo de la consultoria de sistemas esto con el fin de satisfacer la demanda
                     y las necesidades de los habitantes del Estado Plurinacional de Bolivia.
                  </p>
                </div>
              </div>
              <div className="item d-flex justify-content-between align-items-center">
                <img
                  style={{ width: '10%' }}
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/SN%20filosofia.gif" // Asegúrate de que la ruta sea correcta
                  alt="more"
                  className="img-fluid"
                />
                <div className="cont">
                  <h3 className="h3mv">Nuestra Filosofía: Humanizar la Experiencia Tecnológica</h3>
                  <p className="pmv">
                    En el corazón de nuestra filosofía está la humanización de la tecnología. En
                    M@C-SOFTINS, creemos firmemente que cada cliente es único, con necesidades
                    específicas que merecen un enfoque especial. Trabajamos incansablemente para
                    asegurarnos de que tu experiencia con nosotros vaya más allá de un simple
                    servicio.
                  </p>
                </div>
              </div>
              <div className="item d-flex justify-content-between align-items-center">
                <img
                  style={{ width: '10%' }}
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/SN servicios.gif" // Asegúrate de que la ruta sea correcta
                  alt="more"
                  className="img-fluid"
                />
                <div className="cont">
                  <h3 className="h3mv">Nuestros Servicios: Excelencia en Soluciones Tecnológicas</h3>
                  <p  className="pmv">
                    Ofrecemos una amplia gama de servicios tecnológicos respaldados por una
                    sólida experiencia en Microsoft y un compromiso inquebrantable con la
                    calidad y la seguridad. Estamos aquí para hacer que la tecnología funcione a
                    tu favor, permitiéndote enfocarte en el crecimiento de tu negocio.
                  </p>
                </div>
              </div>
              <div className="item d-flex justify-content-between align-items-center">
                <img
                  style={{ width: '10%' }}
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/SN tecnologia.gif" // Asegúrate de que la ruta sea correcta
                  alt="more"
                  className="img-fluid"
                />
                <div className="cont">
                  <h3 className="h3mv">M@C-SOFTINS: Tu Socio Tecnológico de Confianza</h3>
                  <p  className="pmv">
                    Bienvenido a nuestro mundo tecnológico, donde M@C-SOFTINS se convierte en tu
                    socio tecnológico de confianza. Con nuestra experiencia, pasión y
                    dedicación, te ayudaremos a navegar por la era digital en constante
                    evolución. Descubre cómo M@C-SOFTINS puede ser la clave para alcanzar tus
                    objetivos tecnológicos y hacer crecer tu negocio en este emocionante
                    viaje digital.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MisionVision;
