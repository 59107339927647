import React, { useEffect } from 'react';

const Construccion = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Aquí puedes manejar los eventos de teclado si es necesario
      event.preventDefault(); // Previene el comportamiento de las teclas en la página principal
    };

    // Agregar el manejador de eventos cuando el componente se monta
    window.addEventListener('keydown', handleKeyDown);

    // Limpiar el manejador de eventos cuando el componente se desmonta
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <section style={{ width: '100%', height: '100vh' }}>
        <center>
          <img src="https://www.colombiahosting.com.co/blog/wp-content/uploads/2021/08/como-crear-pagina-en-construccion.png" />
        </center>
    </section>
  );
};

export default Construccion;
