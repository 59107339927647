import React from "react";
const Infraestructura = () => {
  return (
    <>
      <section className="s_features_section" style={{ marginTop: "100px" }}>
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="box text-center col-lg-12 m-5 p-5 d-flex flex-column justify-content-center align-items-center">
              <h2
                className="titulos_servicios f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                CIBERSEGURIDAD
              </h2>
              <br />
              <h4
                className="lh-20 f_p f_size_20 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
              Protege Tu Empresa con Nuestros Servicios <br />
              de Ciberseguridad Especializados para PYMEs
              </h4>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="300px"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/security_2.gif"
                  alt="ciberseguridad"
                />
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                En el mundo digital de hoy, cada empresa, sin importar su tamaño, enfrenta riesgos de seguridad que pueden comprometer sus operaciones, reputación y la confianza de sus clientes. Las pequeñas y medianas empresas, en particular, son blancos atractivos para los ciberataques debido a la percepción de que tienen defensas menos robustas.
                <br />
                Entendemos los desafíos únicos que las PYMEs enfrentan cuando se trata de ciberseguridad. Es por eso que hemos diseñado nuestros servicios de ciberseguridad específicamente para satisfacer las necesidades, presupuestos y contextos operativos de las pequeñas y medianas empresas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  Nuestros Servicios Incluyen:
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Evaluaciones de Seguridad Personalizadas:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Identificamos las vulnerabilidades específicas de tu negocio y te proporcionamos un plan claro para abordarlas.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Protección contra Malware y Ransomware:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Implementamos soluciones de vanguardia para proteger tus datos y sistemas contra software malicioso.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Monitoreo y Respuesta a Incidentes:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Nuestro equipo de expertos supervisa tu infraestructura tecnológica en todo momento, listo para responder ante cualquier amenaza.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Formación y Concientización para Empleados:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Evaluamos y mejoramos tu infraestructura de red para garantizar la máxima eficiencia, seguridad y escalabilidad.
                </p>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/ciberseguridad-2.gif"
                  alt="infraestructura_landing"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s_features_section elegirnos">
        <div className="custom_container">
          <div className="row d-flex justify-content-center align-items-center text-center">
            <h1 className="titulos_servicios">¿POR QUÉ ELEGIRNOS?</h1>
            <div className="s_features_item_card">
              <div className="card-MaC-SOFTINS wow fadeInLeft">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/flexibilidad.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Flexibilidad</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                Ofrecemos paquetes escalables <br />
                que se ajustan a tus necesidades y <br />
                crecen con tu empresa. <br />
                </article>
              </div>
              <div className="card-MaC-SOFTINS wow fadeInRight">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/experiencia.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Experiencia</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                Nuestro equipo de expertos <br />
                tiene años de experiencia protegiendo  <br />
                empresas como la tuya.  <br />
                </article>
              </div>
              <div className="card-MaC-SOFTINS wow fadeInLeft">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/calidad.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Compromiso de calidad</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <label className="lh-18">
                  Nos dedicamos a proporcionar el más alto nivel de servicio y soporte a nuestros clientes.
                </label>
              </div>
              <div className="card-MaC-SOFTINS wow fadeInRight">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/insignia.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Compromiso de calidad Y mejora Continua</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <label className="lh-18">
                Nos dedicamos a proporcionar el más
                alto nivel de servicio y soporte a
                nuestros clientes.
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-center mt-5">
              <article className="lh-18">
                  La ciberseguridad no es solo una inversión en tu <strong>infraestructura
                      tecnológica;</strong> es una inversión en la continuidad de tu negocio, la
                  protección de tus clientes y la preservación de tu reputación.
              </article>
              <br />
              <article className="lh-18">
                  <strong>¡No esperes a ser la próxima víctima de un ciberataque! <br /></strong>
                  Contacta con nosotros hoy para aprender más sobre cómo
                  podemos ayudarte a fortalecer tu postura de seguridad.
                  Expertos
              </article>
          </div>
      </section>

    </>
  );
};

export default Infraestructura;
