import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import RoutesConfig from './routes/RoutesConfig'; // Importa el archivo de rutas
import ModalNoticias from './components/Modal'; // Importa el modal de noticias
import { GlobalProvider } from './context/GlobalContext';
const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal
  const [noticias, setNoticias] = useState([]); // Estado para almacenar las noticias obtenidas del API

  // Función para cerrar el modal
  const handleCloseModal = () => {
    setIsModalOpen(false); // Cierra el modal
  };

  // Función para consultar los datos del API
  const fetchNoticias = async () => {
    try {
      const response = await fetch('https://swserviceapp.mac-softins.com/api/getmodaldato');
      const data = await response.json();

      if ( data.nombre) {
        setNoticias(data); // Almacena las noticias si hay datos
        setIsModalOpen(true); // Abre el modal automáticamente si hay noticias
      }
    } catch (error) {
      console.error('Error al obtener datos del API:', error);
      // No abrir el modal si hay un error
    }
  };

  // useEffect para consultar los datos al montar el componente
  useEffect(() => {
    fetchNoticias(); // Llama a la función para obtener los datos del API
  }, []); // Solo se ejecuta al montar el componente

  return (
    <GlobalProvider>
      <Router>
        <div className="App">
          <Header />
          <ModalNoticias isOpen={isModalOpen} onClose={handleCloseModal} noticias={noticias} />
          <RoutesConfig />
          <Footer />
        </div>
      </Router>
    </GlobalProvider>

  );
};

export default App;
