import React from "react";
const Desarrollo = () => {
  return (
    <>
      <section className="s_features_section" style={{ marginTop: "100px" }}>
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="box text-center col-lg-12 m-5 p-5 d-flex flex-column justify-content-center align-items-center">
              <h2
                className="titulos_servicios f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                Desarrolla tu pagina web con nosotros
              </h2>
              <br />
              <h4
                className="lh-20 f_p f_size_20 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                Especialistas en la creación, sitios webs que impulsan tu negocio
              </h4>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="400px"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/consultoria-it.gif"
                  alt="ciberseguridad"
                />
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  En M@C-SOFTINS, nos interesa el dedsarrolo de una pagina web{" "}
                  <strong> Por la creacion y automatizacion creando una imagen coorporativa de su negocio </strong>
                  con más de 5 años de experiencia. Nuestro objetivo es proporcionar soluciones
                  de optimas, que aseguren una experiencia unica{" "}
                  <strong>adaptándonos a las necesidades específicas de cada cliente</strong> y
                  alineados con sus objetivos empresariales y vision.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  Servicios de Desarrollo De Sitios WEB:
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Diseño e implementación de sitio WEB:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Desarrollamos tu sitio web desde cero,
                  asegurandonos una web precisa y adaptada a las necesidades actuales y
                  futuras de tu negocio.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Mantenimiento y Soporte De Sitios WEB:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Ofrecemos mantenimiento preventivo y correctivo para Sitios WEB, actuando rápidamente
                  para resolver incidencias y mantener tu WEB operando sin interrupciones.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Monitoreo y Gestión De Su Dominio:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Implementamos sistemas avanzados de monitoreo 24/7 para detectar y resolver
                  proactivamente problemas antes de que afecten tu negocio.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Optimización y Modernización de Sitio WEB:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Evaluamos y mejoramos tu sitio WEB para garantizar la máxima
                  eficiencia, seguridad y escalabilidad.
                </p>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/sitio-web.webp"
                  alt="infraestructura_landing"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s_features_section elegirnos">
        <div className="custom_container">
          <div className="row d-flex justify-content-center align-items-center text-center">
            <h1 className="titulos_servicios">¿POR QUÉ ELEGIRNOS?</h1>
            <div className="s_features_item_card">
              <div className="card-MaC-SOFTINS wow fadeInLeft">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/contratacion.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Profesionalismo</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                  Nuestro equipo de expertos <br />
                  ofrece un nivel de profesionalismo <br />
                  que garantiza la calidad y <br />
                  eficiencia en todos los proyectos <br />
                  de infraestructura de red.
                </article>
              </div>
              <div className="card-MaC-SOFTINS wow fadeInRight">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/mesa-de-ayuda.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Atención Personalizada</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                  Proporcionamos una atención <br />
                  detallada y personalizada, <br />
                  asegurando que cada solución de <br />
                  red responda a las <br />
                  particularidades de cada cliente.
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Desarrollo;
