import React from 'react';
const Otroservicio = () => {
  return (
    <>
      <section className="section contact-info-service pb-0" id="Servicemac">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-title text-center">
                <h2>Servicios Brindados Por Nuestra Consultora </h2>
                <div className="divider mx-auto my-4"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="rowdata">
            <div className="col-lg-4 col-sm-6 col-md-6">
              <a href="./infraestructura">
                <div className="contact-block mb-4 mb-lg-0">
                  <img width="50%" className="gif" src="https://rath.mac-softins.com/imgpublic/oficialpage/security_1.gif" alt="Infraestructura" />
                  <h5 className="mt-4">Infraestructuras</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <a href="./ciberseguridad">
                <div className="contact-block mb-4 mb-lg-0">
                  <img width="50%" className="gif" src="https://rath.mac-softins.com/imgpublic/oficialpage/security_2.gif" alt="Ciberseguridad" />
                  <h5 className="mt-4">Ciberseguridad</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <a href="./servidores-cloud">
                <div className="contact-block mb-4 mb-lg-0">
                  <img width="50%" className="gif" src="https://rath.mac-softins.com/imgpublic/oficialpage/security_4.gif" alt="Servidores y Cloud" />
                  <h5 className="mt-4">Servidores y Cloud</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <a href="./seguridad-perimetral">
                <div className="contact-block mb-4 mb-lg-0">
                  <img width="50%" className="gif" src="https://rath.mac-softins.com/imgpublic/oficialpage/security_5.gif" alt="Seguridad perimetral" />
                  <h5 className="mt-4">Seguridad <br /> perimetral</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6" id="empresaunid">
                <div className="contact-block mb-4 mb-lg-0">
                  <img width="100%" className="gif" src="https://rath.mac-softins.com/imgpublic/oficialpage/unid.gif" alt="M@C-SOFTINS" />
                  <h5 className="mt-4"></h5>
                </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <a href="./poliza-soporte">
                <div className="contact-block mb-4 mb-lg-0">
                  <img width="50%" className="gif" src="https://rath.mac-softins.com/imgpublic/oficialpage/security_3.gif" alt="Pólizas de soporte IT" />
                  <h5 className="mt-4">Pólizas de <br /> soporte IT</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <a href="./it">
                <div className="contact-block mb-4 mb-lg-0">
                  <img width="50%" className="gif" src="https://rath.mac-softins.com/imgpublic/oficialpage/security_7.gif" alt="Consultoría IT" />
                  <h5 className="mt-4">Consultoría IT</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <a href="./programacion">
                <div className="contact-block mb-4 mb-lg-0">
                  <img width="50%" className="gif" src="https://rath.mac-softins.com/imgpublic/oficialpage/security_6.gif" alt="Programación y Desarrollo de Software" />
                  <h5 className="mt-4">Programación y <br /> Desarrollo de Software</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <a href="./desarollo-web">
                <div className="contact-block mb-4 mb-lg-0">
                  <img width="50%" className="gif" src="https://rath.mac-softins.com/imgpublic/oficialpage/consultoria-it.gif" alt="Desarrollo de Paginas WEB" />
                  <h5 className="mt-4">Desarrollo de Paginas WEB</h5>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Otroservicio;
