import React from "react";
import vpsplan from "./componentes/VpsPlans";
const InfraestructuraIT = () => {
  return (
    <>
      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="box text-center col-lg-12 m-5 p-5 d-flex flex-column justify-content-center align-items-center">
              <h2
                className="titulos_servicios f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                Nuestra area de Infraestructura Tecnologica
              </h2>
              <br />
              <h4
                className="lh-20 f_p f_size_20 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                Potencia y Seguridad De sus servicios
              </h4>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="300px"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/sever.gif"
                  alt="ciberseguridad"
                />
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  En M@C-SOFTINS, sabemos que la correcta gestión de servidores y la eficiencia de las soluciones en la nube son cruciales para la operatividad y el crecimiento de cualquier empresa. <strong> Con más de 6 años de experiencia, </strong> ofrecemos servicios confiables y seguros, diseñados para <strong>maximizar la disponibilidad y el rendimiento de tus aplicaciones y datos críticos.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s_features_section_server">
        <div className="rowit separation">
            <div className="card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="47" height="55" src="https://rath.mac-softins.com/imgpublic/oficialpage/server.gif" alt="Servidores rack"/>
                    </figure>
                </div>
                <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Servidores Rack</p>
            </div>
            <div className="card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="47" height="55" src="https://rath.mac-softins.com/imgpublic/oficialpage/server.gif" alt="Servidores de torre"/>
                    </figure>
                </div>
                <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Servidores De Torre</p>
            </div>
            <div className=" card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="47" height="55" src="https://rath.mac-softins.com/imgpublic/oficialpage/server.gif" alt="Sistemas componibles"/>
                    </figure>
                </div>
                <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Sistemas Componibles</p>
            </div>
            <div className="card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="47" height="55" src="https://rath.mac-softins.com/imgpublic/oficialpage/server.gif" alt="Sistemas hiperconvergentes"/>
                    </figure>
                </div>
                <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Sistemas Hiperconvergentes</p>
            </div>
            <div className=" card">
                <div className="car-img">
                    <figure className="elementor-image-box-img">
                        <img loading="lazy" decoding="async" width="47" height="55" src="https://rath.mac-softins.com/imgpublic/oficialpage/server.gif" alt="Infraestructura de blades"/>
                    </figure>
                </div>
                <p className=" psv" data-wow-delay="0.5s">Infraestructura de Blades</p>
            </div>
        </div>
      </section>
      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  Servidores
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Equipamiento de tipo rack, torre, convergentes o en formato blade para las diferentes cargas de trabajo de cada industria en el ámbito empresarial.
                </h3>



                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Nos encargamos de la configuración, administración y mantenimiento de tus servidores, asegurando que operen con el máximo rendimiento y seguridad. Nuestros planes incluyen tanto servidores dedicados para necesidades específicas, como opciones compartidas más económicas y eficientes.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Almacenamiento de datos
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  El almacenamiento de datos es la retención de información mediante el uso de tecnología desarrollada especialmente para guardar esos datos y mantenerlos lo más accesibles posible.La velocidad y la eficiencia de tu almacenamiento de datos son la clave para el crecimiento empresarial.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Redes (networking)
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Brindar soluciones de redes y seguridad, contamos  con decenas de especialistas certificados ante una amplia variedad de fabricantes. Con base en Potosi, Sucre y Tarija – capacitados en Bolivia en Cisco, Aruba, Sophos, Ruckus.

Las redes de las empresas cada vez tienen que soportar más dispositivos móviles conectados. Para mejorar la velocidad de conexión de cada uno de estos aparatos e incrementar la seguridad, se hace necesaria la implementación de switches de alta gama empresarial.
                </p>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/networking-2.gif"
                  alt="infraestructura_landing"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/servidores_3.svg"
                  alt="infraestructura_landing"
                />
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  Hiper Convergencia
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  La infraestructura Hiperconvergente (HCI) es un sistema unificado y definido por software que reúne todos los elementos de un centro de datos tradicional: almacenamiento, recursos informáticos, red y gestión.
                </h3>

                <section className="s_features_section_server_hiper">
                  <div className="row separation-hiper">
                      <div className="card-hiper">
                          <div className="car-img">
                              <figure className="elementor-image-box-img">
                                  <img loading="lazy" decoding="async" width="47" height="55" src="https://rath.mac-softins.com/imgpublic/oficialpage/cloud.gif" alt="Servidores rack"/>
                              </figure>
                          </div>
                          <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Computo</p>
                      </div>
                      <div className="card-hiper">
                          <div className="car-img">
                              <figure className="elementor-image-box-img">
                                  <img loading="lazy" decoding="async" width="47" height="55" src="https://rath.mac-softins.com/imgpublic/oficialpage/cloud.gif" alt="Servidores de torre"/>
                              </figure>
                          </div>
                          <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Almacenamiento</p>
                      </div>
                      <div className=" card-hiper">
                          <div className="car-img">
                              <figure className="elementor-image-box-img">
                                  <img loading="lazy" decoding="async" width="47" height="55" src="https://rath.mac-softins.com/imgpublic/oficialpage/cloud.gif" alt="Sistemas componibles"/>
                              </figure>
                          </div>
                          <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Elementos de red</p>
                      </div>
                      <div className="card-hiper">
                          <div className="car-img">
                              <figure className="elementor-image-box-img">
                                  <img loading="lazy" decoding="async" width="47" height="55" src="https://rath.mac-softins.com/imgpublic/oficialpage/cloud.gif" alt="Sistemas hiperconvergentes"/>
                              </figure>
                          </div>
                          <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Hiperconvergencia</p>
                      </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  QNAP<small>(nube privada)</small>
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Un NAS (Almacenamiento conectado en red) es un dispositivo de almacenamiento de datos inteligente que le permite almacenar, acceder, gestionar y compartir de forma centralizada todos sus archivos, incluyendo fotos, vídeos, música y documentos. Al conectar un NAS a la red de su oficina, puede crear y compartir un espacio de almacenamiento seguro con familiares/compañeros al que se podrá acceder desde ordenadores o dispositivos móviles. Un NAS es un dispositivo fácil de usar y con muchas funciones, mucho más que una simple solución de copia de seguridad.
                </h3>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/qnap.gif"
                  alt="infraestructura_landing"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/firewall.gif"
                  alt="infraestructura_landing"
                />
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                Seguridad en la red
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Utilice Firewalls de Próxima Generación (Next Generation Firewalls) para sus soluciones de seguridad perimetral que integran la administración de amenazas de manera multicapa y permiten adaptar las características incorporadas a las necesidades específicas de la empresa.
                </h3>

                <section className="s_features_section_server_hiper">
                  <div className="row separation-hiper">
                      <div className="card-hiper">
                          <div className="car-img">
                              <figure className="elementor-image-box-img">
                                  <img loading="lazy" decoding="async" width="47" height="55" src="https://rath.mac-softins.com/imgpublic/oficialpage/cloud.gif" alt="Servidores rack"/>
                              </figure>
                          </div>
                          <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">pfsense</p>
                      </div>
                      <div className="card-hiper">
                          <div className="car-img">
                              <figure className="elementor-image-box-img">
                                  <img loading="lazy" decoding="async" width="47" height="55" src="https://rath.mac-softins.com/imgpublic/oficialpage/cloud.gif" alt="Servidores de torre"/>
                              </figure>
                          </div>
                          <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Mikrotik</p>
                      </div>
                      <div className=" card-hiper">
                          <div className="car-img">
                              <figure className="elementor-image-box-img">
                                  <img loading="lazy" decoding="async" width="47" height="55" src="https://rath.mac-softins.com/imgpublic/oficialpage/cloud.gif" alt="Sistemas componibles"/>
                              </figure>
                          </div>
                          <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Opnsense</p>
                      </div>
                      <div className="card-hiper">
                          <div className="car-img">
                              <figure className="elementor-image-box-img">
                                  <img loading="lazy" decoding="async" width="47" height="55" src="https://rath.mac-softins.com/imgpublic/oficialpage/cloud.gif" alt="Sistemas hiperconvergentes"/>
                              </figure>
                          </div>
                          <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Cisco ASA</p>
                      </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  Servicios<small>(integrales)</small>
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  solcuiones  en distintas areas telefonia, Soluciones microsoft, impresion ,Creacion de tecnologia basadas en arduino, raspberry Pi, PLC
                </h3>
                <li>Ofrecemos soluciones integrales para aumentar el rendimiento empresarial.​</li>
                <section className="s_features_section_server_hiper">
                  <div className="row separation-hiper">
                      <div className="card-hiper">
                          <div className="car-img">
                              <figure className="elementor-image-box-img">
                                  <img loading="lazy" decoding="async" src="https://rath.mac-softins.com/imgpublic/oficialpage/microsoft.gif" alt="Servidores rack"/>
                              </figure>
                          </div>
                          <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Lincencias PKS</p>
                      </div>
                      <div className="card-hiper">
                          <div className="car-img">
                              <figure className="elementor-image-box-img">
                                  <img loading="lazy" decoding="async"  src="https://rath.mac-softins.com/imgpublic/oficialpage/microsoft.gif" alt="Servidores de torre"/>
                              </figure>
                          </div>
                          <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Licencias EDS</p>
                      </div>
                      <div className=" card-hiper">
                          <div className="car-img">
                              <figure className="elementor-image-box-img">
                                  <img loading="lazy" decoding="async"  src="https://rath.mac-softins.com/imgpublic/oficialpage/microsoft.gif" alt="Sistemas componibles"/>
                              </figure>
                          </div>
                          <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Licencias OEM</p>
                      </div>
                      <div className="card-hiper">
                          <div className="car-img">
                              <figure className="elementor-image-box-img">
                                  <img loading="lazy" decoding="async" src="https://rath.mac-softins.com/imgpublic/oficialpage/microsoft.gif" alt="Sistemas hiperconvergentes"/>
                              </figure>
                          </div>
                          <p className="lh-18 l_height30 f_400 wow fadeInRight psv" data-wow-delay="0.5s">Licencias OPEN</p>
                      </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/telefonia.gif"
                  alt="infraestructura_landing"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InfraestructuraIT;
