import React from 'react';

const Ceo = () => {
  return (
    <div className="ceo-page">
      <section className="ceo-introduction">
        <div className="container">
          <h1 className="text-center">Conozca a nuestro CEO</h1>
          <div className="row">
            <div className="col-lg-6">
              <img
                src="https://rath.mac-softins.com/imgpublic/oficialpage/1kstping.jpg"
                alt="CEO"
                className="img-fluid rounded-circle"
              />
            </div>
            <div className="col-lg-6">
              <h2 className="ceo-name">Ricardo Rojas Cruz</h2>
              <p className="ceo-description">
                Ricardo Rojas Cruz, nacido en 1990 en Potosí, Bolivia, es un destacado genio tecnológico e innovador en el campo de la tecnología y la robótica. Con una sólida formación académica y una carrera llena de logros, Ricardo ha hecho importantes contribuciones en su área de especialización.
                <br /><br />
                Es Ingeniero en Sistemas y ha complementado su formación con una Ingeniería en Mecatrónica Electrónica, aportando una perspectiva multidisciplinaria a sus proyectos. Su pasión por la ciberseguridad lo llevó a obtener una Maestría en Ciberseguridad, con especialización en el rol de Chief Information Security Officer (CISO), consolidando su expertise en la protección de información y sistemas críticos.
                <br /><br />
                Además de su formación académica, Ricardo ha realizado diplomados en Educación Superior, reflejando su compromiso con la formación continua y el desarrollo profesional. Su interés en la neurociencia le ha proporcionado una visión única sobre la intersección entre tecnología y el funcionamiento cognitivo, enriqueciendo sus enfoques innovadores en el diseño y desarrollo de soluciones tecnológicas.
                <br /><br />
                Ricardo Rojas Cruz es reconocido por su capacidad para liderar y ejecutar proyectos complejos, impulsando la tecnología hacia nuevas fronteras y creando soluciones innovadoras que impactan positivamente en su entorno.
              </p>
              <p className="ceo-quote">
                "Ricardo Rojas Cruz es reconocido por su capacidad para liderar y ejecutar proyectos complejos, impulsando la tecnología hacia nuevas fronteras y creando soluciones innovadoras que impactan positivamente en su entorno."
              </p>
              <div className="ceo-buttons">
                <div className="contenedor-redes-sociales">
                  <a className="btn-social facebook" href="https://www.facebook.com/ricky.rojasdelacruz" target="_blank" rel="noopener noreferrer">
                    <span className="circulo"><i className="fab fa-facebook-f"></i></span>
                    <span className="titulo">Facebook</span>
                    <span className="titulo-hover">Ingresar</span>
                  </a>
                  <a className="btn-social whatsapp" href="https://wa.me/+59167916774?text=me%20gustaria%20saber%20mas%20sobre%20su%20negocio" target="_blank" rel="noopener noreferrer">
                    <span className="circulo"><i className="fab fa-whatsapp"></i></span>
                    <span className="titulo">WhatsApp</span>
                    <span className="titulo-hover">Contactar</span>
                  </a>
                  <a className="btn-social portfolio" href="https://ricardo.rojas.cruz.mac-softins.com/" target="_blank" rel="noopener noreferrer">
                    <span className="circulo"><i className="fas fa-briefcase"></i></span>
                    <span className="titulo">Portafolio</span>
                    <span className="titulo-hover">Ingresar</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ceo-milestones">
        <div className="container">
          <h2 className="text-center">Bajo su liderazgo, Ricardo ha alcanzado hitos significativos:</h2>
          <ul className="milestones-list">
            <li>Aumento del Ingreso de la Empresa: Logró incrementar los ingresos de la empresa en un 150% en los últimos 5 años, demostrando una capacidad excepcional para impulsar el crecimiento financiero.</li>
            <li>Innovación Tecnológica: Introdujo tecnologías innovadoras que han establecido nuevos estándares en la industria, destacándose por su visión y habilidad para implementar soluciones vanguardistas.</li>
            <li>Expansión Internacional: Ampliado la presencia de la empresa en mercados internacionales, fortaleciendo su posición global y abriendo nuevas oportunidades de negocio.</li>
            <li>Construcción de un Equipo Fuerte: Formó un equipo sólido y dedicado a la innovación y la excelencia, cultivando un entorno de trabajo que fomenta el desarrollo continuo y el éxito colaborativo.</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Ceo;
