import React, { useEffect, useState } from 'react';
const HistoriaSection = () => {
  const [historiaData, setHistoriaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Consumir el endpoint cuando el componente se monta
    const fetchHistoriaData = async () => {
      try {
        const response = await fetch('https://swserviceapp.mac-softins.com/api/sectionhistoria');
        if (!response.ok) {
          throw new Error('Error al consumir la API');
        }
        const data = await response.json();
        setHistoriaData(data); // Guardar la data en el estado
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchHistoriaData();
  }, []);

  // Mostrar mensajes de error o de carga
  if (loading) return <p>Cargando...</p>;
  if (error) return <p>Error: {error}</p>;

  // Si ya tenemos los datos, pintarlos en los campos correspondientes
  return (
    <div className="historia-container">
      {historiaData && (
        <>
          <div className="historia-content">
            <div dangerouslySetInnerHTML={{ __html: historiaData.historia }} /> {/* Mostrar historia */}
          </div>

          <div className="timeline-container">
            <center>
              <h2>Eventos Históricos</h2>
            </center>
            <ul className="timeline">
              {historiaData.eventos.map((evento, index) => (
                <li key={index} className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                  <div className="content">
                    <span className="year">{evento.año}</span>
                    <h3>{evento.titulo}</h3>
                    <p dangerouslySetInnerHTML={{ __html: evento.evento }} /> {/* Mostrar la descripción del evento */}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default HistoriaSection;
