import React from 'react';
const Banner = () => {
  return (
    <section className="banner">
      <video autoPlay muted loop className="banner-video">
        <source src="/video.webm" type="video/webm" />
        Your browser does not support the video tag.
      </video>
      <div className="banner-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-xl-7">
              <div className="block">
                <div className="divider mb-3"></div>
                <span className="text-uppercase text-sm letter-spacing">Expertos en tecnología & asesoramiento</span>
                <h1 className="mb-3 mt-3 colorh1">M@C - SOFTINS</h1>
                <p className="mb-4 pr-5">
                  Consultora de sistemas e Informática y venta de equipamiento Tecnológico<br />
                  Contamos con las mejores marcas<br />
                  Desarrollamos Sistemas y Aplicaciones
                </p>
                <div className="btn-container">
                  <a href="about" rel="noopener noreferrer" className="btn btn-main-2 btn-icon btn-round-full">
                    Conocer más sobre nosotros <i className="icofont-simple-right ml-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
