import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2'; // Import SweetAlert
import { GlobalContext } from '../context/GlobalContext';
const apiUrl = process.env.REACT_APP_API_URL;

const Footer = () => {
  const { estadisticas } = useContext(GlobalContext);
  const [email, setEmail] = useState('');
  const [socialButtons, setSocialButtons] = useState([]); // Estado para almacenar los botones sociales

  // Función para obtener los botones de redes sociales desde la API
  const fetchSocialButtons = async () => {
    try {
      const response = await fetch('https://swserviceapp.mac-softins.com/api/getsocialbutton');
      if (!response.ok) {
        throw new Error('Error al obtener los botones sociales.');
      }
      const data = await response.json();
      setSocialButtons(data); // Guardar los botones sociales en el estado
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message,
        confirmButtonText: 'Aceptar',
      });
    }
  };

  // Ejecutar la función cuando el componente se monte
  useEffect(() => {
    fetchSocialButtons();
  }, []);

  const handleSubscribe = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    if (email) {
      try {
        // Sending a POST request to the subscription endpoint
        const response = await fetch(apiUrl + 'suscriptionmac', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }), // Send the email in the request body
        });

        if (!response.ok) {
          throw new Error('Error al suscribirse.');
        }

        const data = await response.json();
        // Display success message using SweetAlert
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: '¡Te has suscrito exitosamente!',
          confirmButtonText: 'Aceptar',
        });

        setEmail(''); // Reset the email state after successful subscription
      } catch (error) {
        console.error('Error:', error);

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message,
          confirmButtonText: 'Aceptar',
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Campo Requerido',
        text: 'Por favor, ingresa un correo electrónico válido.',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  return (
    <footer className="footer section gray-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 mr-auto col-sm-6">
            <div className="widget mb-5 mb-lg-0">
              <div className="logo mb-4">
                <img src={require('../assets/images/logo.png')} alt="Logo" className="img-fluid" />
              </div>
              <p>
                Brindamos soluciones confiables y eficientes, asumiendo con responsabilidad cada proyecto. Nuestro equipo
                simplifica los procesos complejos y asegura resultados personalizados, siempre comprometidos con la excelencia y la innovación tecnológica.
              </p>
              {/* Pintar los botones de redes sociales dinámicamente */}
              <ul className="list-inline footer-socials mt-4">
                {socialButtons.length > 0 ? (
                  socialButtons.map((button, index) => (
                    <li className="list-inline-item" key={index}>
                      <a href={button.url} target="_blank" rel="noopener noreferrer">
                        <i className={button.icono}></i>
                      </a>
                    </li>
                  ))
                ) : (
                  <p>Cargando botones sociales...</p>
                )}
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="widget mb-5 mb-lg-0">
              <h4 className="text-capitalize mb-3">M@C-SOFTINS</h4>
              <div className="divider mb-4"></div>
              <ul className="list-unstyled footer-menu lh-35">
                <li><a href="/InfraestructuraTI">Infraestructura Tecnológica</a></li>
                <li><a href="/DesarrolloApp">Desarrollo y Aplicaciones</a></li>
                <li><a href="/vpshost">Hosting y VPS</a></li>
                <li><a href="/Venta-Importacion">Venta e Importación de Equipos</a></li>
                <li><a href="https://fae-mac.mac-softins.com/">Fundación FAEM@C</a></li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="widget widget-contact mb-5 mb-lg-0">
              <h4 className="text-capitalize mb-3">Ponte en Contacto</h4>
              <div className="divider mb-4"></div>
              <div className="footer-contact-block mb-4">
                <div className="icon d-flex align-items-center">
                  <i className="icofont-email mr-3"></i>
                  <span className="h6 mb-0">Soporte las 24/7</span>
                </div>
                <h4 className="mt-2">
                  <a href="mailto:soporte@mac-softins.com">soporte@mac-softins.com</a>
                </h4>
              </div>
              <div className="footer-contact-block">
                <div className="icon d-flex align-items-center">
                  <i className="icofont-support mr-3"></i>
                  <span className="h6 mb-0">LUN a VIERNES: 08:30 - 18:00</span>
                </div>
                <h4 className="mt-2">
                  <a href="tel:+59167916774">+591 67916774</a>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-btm py-4 mt-5">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6">
              <div className="copyright">
                © Copyright Reserved to <span className="text-color"></span> by <a href="https://mac-softins.com/" target="_blank" rel="noopener noreferrer">M@c-softins</a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="subscribe-form text-lg-right mt-5 mt-lg-0">
                <form action="#" className="subscribe" onSubmit={handleSubscribe}>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Tu dirección de correo electrónico"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // Update state on input change
                    required // Required field
                  />
                  <button type="submit" className="btn btn-main-2" id="btn-round-full-k">Suscribete</button>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <a className="backtop js-scroll-trigger" href="#top">
                <i className="icofont-long-arrow-up"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
