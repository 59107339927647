import React, { useEffect, useState } from 'react';
import { FaUser, FaFlag, FaUserMd, FaGlobe } from 'react-icons/fa';

const CTA = () => {
  const [estadisticas, setEstadisticas] = useState({
    visitas: 0,
    clientes: 0,
    host_administrados: 0,
    sistemas_comerciales: 0
  });

  useEffect(() => {
    // Recuperar datos del localStorage
    const storedEstadisticas = localStorage.getItem('estadisticas');
    if (storedEstadisticas) {
      setEstadisticas(JSON.parse(storedEstadisticas));
    }
  }, []); // El array vacío asegura que esto se ejecute una sola vez al montar el componente

  return (
    <section className="cta-section">
      <div className="container">
        <div className="cta position-relative">
          <div className="row">
            {/* Primer bloque - Happy People */}
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="counter-stat">
                <FaUser className="icon" />
                <span className="h3">{estadisticas.visitas.toLocaleString()}</span>
                <p>Visitas</p>
              </div>
            </div>

            {/* Segundo bloque - Surgery Completed */}
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="counter-stat">
                <FaFlag className="icon" />
                <span className="h3">{estadisticas.clientes}</span>+
                <p>Clientes</p>
              </div>
            </div>

            {/* Tercer bloque - Expert Doctors */}
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="counter-stat">
                <FaUserMd className="icon" />
                <span className="h3">{estadisticas.host_administrados}</span>+
                <p>Hosts Administrados</p>
              </div>
            </div>

            {/* Cuarto bloque - Worldwide Branch */}
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="counter-stat">
                <FaGlobe className="icon" />
                <span className="h3">{estadisticas.sistemas_comerciales}</span>
                <p>Sistemas Comerciales</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
