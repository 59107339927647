import React, { useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const Banner = () => {
  const [isLoading, setIsLoading] = useState(true); // Estado para controlar el preloader

  // Componente del modelo 3D
  const Model = ({ onLoaded }) => {
    const gltf = useLoader(GLTFLoader, '/d3.glb'); // Ruta al archivo GLB en la carpeta public
    useEffect(() => {
      if (gltf) {
        onLoaded(); // Oculta el preloader cuando el primer modelo está cargado
      }
    }, [gltf]); // `onLoaded` no necesita estar en las dependencias

    return (
      <>
        {/* Renderizamos ambos modelos */}
        <primitive object={gltf.scene} />
      </>
    );
  };

  // Función que se llama cuando el modelo ha terminado de cargar
  const handleModelLoaded = () => {
    setIsLoading(false); // Oculta el preloader
  };

  return (
    <section className="banner">
      {/* Video de fondo */}
      <video autoPlay muted loop className="banner-video">
        <source src="/360.mp4" type="video/mp4" />
        Tu navegador no soporta el video.
      </video>

      {/* Contenido principal del banner */}
      <div className="banner-content">
        <div className="container">
          <div className="row360">
            <div className="col-lg-8 col-md-12 col-xl-7">
              <div className="block">
                <div className="divider mb-3"></div>
                <span className="text-uppercase text-sm letter-spacing">Entrena, asiste y gestiona</span>
                <h1 className="mb-3 mt-3 colorh1">VR - Orbit360°</h1>
                <p className="mb-4 pr-5">
                  Digitaliza y potencia tus procesos de aprendizaje, ejecución y análisis mediante
                  <br />
                  el uso de la realidad virtual, realidad aumentada y sus datos.
                  <br />
                  Lentes, web y móviles.
                </p>
                <div className="btn-container">
                  <a href="/contact" rel="noopener noreferrer" className="btn btn-main-2 btn-icon btn-round-full">
                    Contáctanos <i className="icofont-simple-right ml-2"></i>
                  </a>
                  <a
                    href="https://www.tourvirtuales360.cl/?gad_source=1&gclid=CjwKCAjwpbi4BhByEiwAMC8JnU93K8-O3kfAHLR8Phnv-61rYEAljljcr0GCzB0xV3p_LyJDMXKf9xoC6OIQAvD_BwE"
                    className="play_button_with_text large nectar_video_lightbox magnific-popup"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <span className="screen-reader-text"><i className="fa fa-video"></i></span>
                      <span className="play">
                        <span className="inner-wrap inner">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="40px"
                            height="40px"
                            x="0px"
                            y="0px"
                            viewBox="0 0 600 800"
                            enableBackground="new 0 0 600 800"
                            xmlSpace="preserve"
                          >
                            <path fill="none" d="M0-1.79v800L600,395L0-1.79z"></path>
                          </svg>
                        </span>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>

            {/* Sección para renderizar el modelo 3D */}
            <div className="col-lg-4 col-md-12 col-xl-7">
              <div className="canvas-container">
                {isLoading && (
                  <div className="preloader">
                    <div className="spinner"></div>
                    <p>Cargando el modelo...</p>
                  </div>
                )}

                <Canvas camera={{ position: [10, 30, 50], zoom: 0.8 }}>
                  <OrbitControls enableZoom={true} minDistance={5} maxDistance={15} />
                  <ambientLight intensity={0.6} />
                  <Model onLoaded={handleModelLoaded} /> {/* Pasamos la función de carga al modelo */}
                </Canvas>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
