import React from "react";

const Programacion = () => {
  return (
    <>
      <section className="s_features_section" style={{ marginTop: "100px" }}>
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="box text-center col-lg-12 m-5 p-5 d-flex flex-column justify-content-center align-items-center">
              <h2
                className="titulos_servicios f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
              PROGRAMACIÓN Y DESARROLLO <br />
              DE SOFTWARE
              </h2>
              <br />
              <h4
                className="lh-20 f_p f_size_20 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
              Simplifica Tu Licenciamiento de Software <br />
              con Nuestros Servicios Especializados para PYMEs
              </h4>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="300px"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/security_6.gif"
                  alt="ciberseguridad"
                />
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                    En el complejo mundo tecnológico de hoy, gestionar las <strong> licencias
                        de software</strong> de tu empresa puede ser una tarea desafiante, pero
                    esencial para el cumplimiento legal, la optimización de recursos y el
                    ahorro de costos. Es aquí donde <strong>nuestros servicios de
                        licenciamiento</strong> entran en juego,<strong> ofreciéndote la tranquilidad</strong> de que
                    tu empresa <strong>opera con eficiencia y dentro de la legalidad.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  ¿Por Qué es Crucial un Manejo Efectivo del Licenciamiento?
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Cumplimiento Legal:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Acceso a nuestro equipo de expertos IT para solucionar cualquier problema técnico, ya sea de forma remota o in situ.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Optimización de Costos:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Acceso a nuestro equipo de expertos IT para solucionar cualquier problema técnico, ya sea de forma remota o in situ.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Eficiencia Operativa:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Acceso a nuestro equipo de expertos IT para solucionar cualquier problema técnico, ya sea de forma remota o in situ.
                </p>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/programacion-1.gif"
                  alt="infraestructura_landing"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/programacion-2.gif"
                  alt="infraestructura_landing"
                />
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  Nuestros Servicios Incluyen:
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Auditoría de Licencias:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Realizamos un inventario completo de tu software, asegurando que cada producto esté correctamente licenciado.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Gestión de Licencias:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Administramos tus licencias para maximizar su eficacia, incluyendo renovaciones y actualizaciones.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Asesoramiento en Compras:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Te orientamos sobre las mejores opciones de licenciamiento basadas en tus necesidades específicas y potencial de crecimiento.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Soporte Continuo:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Ofrecemos asistencia continua para resolver cualquier duda o problema relacionado con tus licencias de software.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s_features_section elegirnos">
        <div className="custom_container">
          <div className="row d-flex justify-content-center align-items-center text-center">
            <h1 className="titulos_servicios">Beneficios de Trabajar Con Nosotros</h1>
            <div className="s_features_item_card">
              <div className="card-MaC-SOFTINS wow fadeInLeft">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/experiencia_1.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Experiencia Comprobada</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                Con años de experiencia en
                licenciamiento de software,
                estamos equipados para manejar
                todas tus necesidades de forma efectiva.
                </article>
              </div>
              <div className="card-MaC-SOFTINS wow fadeInRight">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/adaptar.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Soluciones a Medida</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                Entendemos que cada PYME es
                única. Nuestros servicios se adaptan
                a tus requerimientos específicos.
                </article>
              </div>
            </div>
            <div className="col-md-8 text-center mt-5">

            <h2 className="text-uppercase">¡No Dejes que el Licenciamiento de Software Sea un Dolor de
                Cabeza!</h2>
            <br />
            <article className="lh-18">
                Permite que nuestros expertos te guíen a través del laberinto del
                <strong>licenciamiento de software</strong>, asegurando que <br /> tu empresa no solo
                cumpla con la ley, sino que también <strong> optimice sus inversiones en
                    tecnología.</strong>
            </article>
        </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Programacion;
