import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const Viewnoticia = () => {
  const { id } = useParams();
  const [noticia, setNoticia] = useState(null);
  const [areas, setAreas] = useState([]);
  const [urlimg, setUrlimg] = useState('');

  useEffect(() => {
    const fetchNoticia = async () => {
      try {
        const response = await fetch(`${apiUrl}getnoticiafind/${id}`);
        const data = await response.json();

        setNoticia(data.noticias);
        setAreas(data.areas);
        setUrlimg(data.url);
      } catch (error) {
        console.error('Error al consumir la API:', error);
      }
    };

    fetchNoticia();
  }, [id]);

  return (
    <section className="view-noticia">
      <div className="row">
        {/* Sección de Noticias */}
        <div className="col-md-8">
          <div className="news-card" key={noticia?.id}>
            {noticia ? (
              <>
                <div className="news-image">
                  {urlimg && (
                    <img
                      src={`${urlimg}/${noticia.url}`}
                      alt={noticia.name}
                      className="img-fluid"
                    />
                  )}
                </div>
                <div className="news-content">
                  <h2>{noticia.name}</h2>
                  <div
                    className="news-description"
                    dangerouslySetInnerHTML={{ __html: noticia.desccrition }}
                  />
                </div>
              </>
            ) : (
              <p>No hay noticias disponible</p>
            )}
          </div>
        </div>

        {/* Sección de Categorías */}
        <div className="col-lg-4">
          <div className="sidebar-wrap pl-lg-4 mt-5 mt-lg-0">
            <div className="sidebar-widget category mb-3">
              <h5 className="mb-4">Áreas De Nuestros Logros</h5>
              <ul className="list-unstyled">
                {areas.length > 0 ? (
                  areas.map((area, index) => (
                    <li className="align-items-center" key={index}>
                      <Link className="dropdown-item" to={`/verareanoticias/${area.id}`}>
                        {area.name}
                      </Link>
                    </li>
                  ))
                ) : (
                  <p>No hay áreas disponibles</p>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Viewnoticia;
