import React from 'react';
import img1 from '../assets/images/about/1.jpeg';
import img2 from '../assets/images/about/2a.png';
import img3 from '../assets/images/about/posmachine.png';

const About = () => {
  return (
    <section className="section_about">
      <div className="container">
        <div className="rowabout align-items-center">
          {/* Primera columna de imágenes */}
          <div className="col-lg-4 col-sm-6">
            <div className="about-img">
              <img src={img1} alt="" className="img-fluid" />
              <img src={img2} alt="" className="img-fluid mt-4" />
            </div>
          </div>

          {/* Segunda columna de imágenes */}
          <div className="col-lg-4 col-sm-6">
            <div className="about-img1 mt-4 mt-lg-0">
              <img src={img3} alt="" className="img-fluid" />
            </div>
          </div>

          {/* Tercera columna de contenido */}
          <div className="col-lg-4">
            <div className="about-content pl-4 mt-4 mt-lg-0">
              <h2 className="title-color">Asesoramiento En Desarrollo De Sistemas</h2>
              <p className="mt-4 mb-5" style={{ textAlign: "left" }}>
                Ofrecemos una gama completa de servicios de TI, incluyendo infraestructuras, ciberseguridad, servidores y cloud, y seguridad perimetral. También brindamos pólizas de soporte IT, consultoría tecnológica, y desarrollo de software personalizado para optimizar y proteger las operaciones de su empresa en el entorno digital.
              </p>
              <a href="DesarrolloApp" className="btn btn-main-2 btn-round-full btn-icon">
                Sistemas_&_Aplicaciones<i className="icofont-simple-right ml-3"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
