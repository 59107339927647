import React from 'react';
import { FaFlask, FaHeart, FaTooth, FaCrutch, FaBrain, FaDna,FaLaptop, FaBone ,FaDog,FaMicrophone,FaTv,FaWarehouse,FaMapMarkerAlt,FaHardHat,FaCalculator,FaUsers } from 'react-icons/fa';
import { HiScissors } from 'react-icons/hi';  // Importar íconos como 'react-icons/fa'
import { AiOutlineTeam } from 'react-icons/ai';
import { FaChalkboard,FaNetworkWired,FaBirthdayCake,FaCube } from 'react-icons/fa';

const Services = () => {
  const services = [
    { icon: <FaFlask />, title: 'SGL', description: 'Sistema de gestion y manejo documental de laboratorios quimicos como mimnerologicos' },
    { icon: <FaHeart />, title: 'SCMC', description: 'Sistemas de gestion y control de signos vitales y hoja de enfermeria, con la poderosa tecnologia IOT' },
    { icon: <FaTooth />, title: 'SGCD', description: 'Sistema  de gesticon y control dental  mediante odontograma e historial dental, virtual por paciente' },
    { icon: <FaLaptop  />, title: 'POS', description: 'Sistemas  de punto de venta  y control de inventario con lso distintos  tipo de facturacion inmpuestos por el SIAT.' },
    { icon: <FaDog  />, title: 'SisVet', description: 'Sistema de control seguimiento veterinario, con control de ventas de alimento balanceado para mascotas' },
    { icon: <FaMicrophone />, title: 'Streamer Radio', description: 'Aplicacion de Radio en linea para cualquier emisora, costo del host es por año' },
    { icon: <FaTv  />, title: 'Streamig TV', description: 'Aplicacion de televicion en linea mas su servidor, pago del host es anual esta proevido difundir peliculas en estreno' },
    { icon: <FaWarehouse   />, title: 'SALMI', description: 'sistemas  y aplicacion de contorl y manejo de almacenes y compra de activos,  con las normas de SIGEP ' },
    { icon: <FaMapMarkerAlt    />, title: 'SPGA', description: 'Sistema de posicionamiento global y contorl de GPS,  el gps y la instalacion vienen por separado' },
    { icon: <FaHardHat />, title: 'SIADMIPO', description: 'Sistema de administracion y control de entrada y salida de minerales con aplicaicon de conexion abalanza de alto Tonelaje' },
    { icon: <FaCalculator />, title: 'COVEMIN', description: 'Sistema de Contorl y venta de minerales,el sistema cuenta con una base de datos de todos los puntos de venta autorizados ' },
    { icon: <HiScissors />, title: '100PreBella', description: 'Sistema de control De corte de cabello, peinados y maquillajes para salones de belleza con Aplicacion de reserva y catalogos' },
    { icon: <FaUsers />, title: 'AppMeet', description: 'Sistema de gestion y control de reuniones en tiempo real, haciendo uso de la herramienta jitsi' },
    { icon: <AiOutlineTeam />, title: 'Mac-Scrum', description: 'Sistema de gestion y control de trabajos  haciendo uso y aplicacion de la metodologia scrum' },
    { icon: <FaChalkboard />, title: 'Mi Negocio', description: 'Sistema de gestion y control tu primer negocio donde puedes publicar y mejorar creado  para las Mypes' },
    { icon: <FaNetworkWired />, title: 'SCDA', description: 'Sistema de control de  difucion de alimento balanceado orientado al area ganadera de Bolivia ' },
    { icon: <FaBirthdayCake  />, title: 'SGP', description: 'Sistema de gestion y venta de producto pasteleros,con coneccion a hornos inteligentes' },
    { icon: <FaCube  />, title: 'CNC', description: 'Creacion de Software hardware de imprecion y grabado de figuras o estampado  apedido ' },
  ];

  return (
    <section className="section service gray-bg ">
      <div className="container">
        <div className="rowdev justify-content-center">
          <div className="col-lg-7 text-center">
            <div className="section-title">
              <h2>Nuestros Sistemas</h2>
              <div className="divider mx-auto my-4"></div>
              <p>Somos una empresa multidisciplinaria que ofrece una amplia gama de servicios en tecnología y seguridad. Desde la infraestructura hasta la ciberseguridad, brindamos soluciones robustas y adaptables a las necesidades de tu empresa. Nuestros servicios incluyen la gestión de servidores y la nube, seguridad perimetral, pólizas de soporte IT, consultoría en tecnologías de la información, y desarrollo de software personalizado. Contamos con un equipo especializado para asegurar que cada área de tu empresa esté protegida y optimizada con las mejores prácticas del mercado.</p>
            </div>
          </div>
        </div>

        <div className="rowdev">
          {services.map((service, index) => (
            <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
              <div className="service-item mb-4">
                <div className="icon d-flex align-items-center">
                  <div className="text-lg">
                    <i>
                      {service.icon}
                    </i>
                  </div>
                  <h4 className="mt-3 mb-3">{service.title}</h4>
                </div>
                <div className="content">
                  <p className="mb-4">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
