import React from 'react';
import SomeComponent from './componentes/1_360'; // Ajusta según la ubicación correcta
import Row360Section from './componentes/2_360'; // Ajusta según la ubicación correcta
import ImportSection from './componentes/3_360'; // Ajusta según la ubicación correcta
import AppointmentSection from './componentes/4_360'; // Ajusta según la ubicación correcta
const MyComponent = () => {
  return (
    <div>
      <SomeComponent />
      <Row360Section />
      <ImportSection />
      <AppointmentSection />
    </div>
  );
};

export default MyComponent;
