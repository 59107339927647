import React from 'react';

const Section2 = () => {
  return (
    <section className="welcome-service slider mt-0 lh-18">
      <div className="container text-center">
        <h4 className="h5mvs">
          En M@C-SOFTINS, te ofrecemos una gama de servicios tecnológicos personalizados para tu empresa. <br />
          Desde ciberseguridad hasta consultoría informática, estamos aquí para impulsar tu éxito. <br />
        </h4>

        {/* Boxes */}
        <div className="row d-flex align-items-center" >

          {/* Box-1 */}
          <a  href="/infraestructura">
            <div className="mt-4 col-md-6 item-circuit" data-href="./infraestructura">
              <div className="box">
                <img style={{ width: '15%' }} src="https://rath.mac-softins.com/imgpublic/oficialpage/SEinfraestructura.gif" alt="INFRAESTRUCTURA" className="img-fluid" />
                <h3>INFRAESTRUCTURA</h3>
                <div className="line m-auto"></div>
                <p>
                  La infraestructura adecuada es fundamental para el éxito de tu empresa. En M@C-SOFTINS, no solo
                  entendemos la importancia de una base sólida, sino que la construimos para ti. Diseñamos,
                  configuramos y optimizamos proyectos para que tu infraestructura sea eficiente y confiable.
                  Confía en nosotros para impulsar tu negocio hacia un futuro tecnológico más seguro y eficiente.
                </p>
              </div>
            </div>
          </a>
          {/* Box-2 */}
          <a  href="/ciberseguridad">
            <div className="mt-4 col-md-6 item-circuit" data-href="./ciberseguridad">
              <div className="box">
                <img style={{ width: '15%' }} src="https://rath.mac-softins.com/imgpublic/oficialpage/SEciberseguridad.gif" alt="CIBERSEGURIDAD" className="img-fluid" />
                <h3>CIBERSEGURIDAD</h3>
                <div className="line m-auto"></div>
                <p>
                  En M@C-SOFTINS, la seguridad de tu empresa es nuestra principal preocupación. Nuestros expertos en
                  ciberseguridad están comprometidos en mantener tus sistemas y datos a salvo de las crecientes
                  amenazas cibernéticas. Con un enfoque personalizado, analizamos tus necesidades y diseñamos
                  estrategias sólidas para proteger tu reino digital. M@C-SOFTINS, tu aliado contra los hackers y el
                  malware.
                </p>
              </div>
            </div>
          </a>
          {/* Box-3 */}
          <a  href="/servidores-cloud">
            <div className="mt-4 col-md-6 item-circuit" data-href="./servidores-cloud">
              <div className="box">
                <img style={{ width: '15%' }} src="https://rath.mac-softins.com/imgpublic/oficialpage/servidores-cloud.gif" alt="SERVIDORES Y CLOUD" className="img-fluid" />
                <h3>SERVIDORES Y CLOUD</h3>
                <div className="line m-auto"></div>
                <p>
                  Actualiza tu infraestructura tecnológica con nuestros servicios de Servidores y Cloud. En M@C-SOFTINS,
                  nos especializamos en implementar y mantener servidores físicos y virtuales basados en
                  Microsoft, Linux y MacOS, así como en la virtualización de plataformas en la nube. Nuestro
                  equipo experto garantiza una integración completa para una gestión eficiente y estable de tu
                  sistema. ¡Conoce cómo podemos potenciar tu empresa!
                </p>
              </div>
            </div>
          </a>

          {/* Box-4 */}
          <a  href="/poliza-soporte">
            <div className="mt-4 col-md-6 item-circuit" data-href="./poliza-soporte">
              <div className="box">
                <img style={{ width: '15%' }} src="https://rath.mac-softins.com/imgpublic/oficialpage/SEpolizadesoporte.gif" alt="PÓLIZAS DE SOPORTE IT" className="img-fluid" />
                <h3>PÓLIZAS DE SOPORTE IT</h3>
                <div className="line m-auto"></div>
                <p>
                  En M@C-SOFTINS, entendemos que la tecnología no siempre es perfecta, pero tu experiencia con nosotros
                  puede serlo. Nuestras pólizas de soporte están diseñadas para brindarte tranquilidad y
                  eficiencia. Con ingenieros certificados y un compromiso inquebrantable con la calidad, estamos
                  aquí para solucionar cualquier problema y garantizar que tu empresa funcione sin problemas. Tu
                  satisfacción, nuestra meta.
                </p>
              </div>
            </div>
          </a>
          {/* Box-5 */}
          <a  href="/it">
            <div className="mt-4 col-md-6 item-circuit" data-href="./it">
              <div className="box">
                <img style={{ width: '15%' }} src="https://rath.mac-softins.com/imgpublic/oficialpage/SEconsultoriainformatica.gif" alt="CONSULTORÍA IT" className="img-fluid" />
                <h3>CONSULTORÍA IT</h3>
                <div className="line m-auto"></div>
                <p>
                  En M@C-SOFTINS, no solo somos expertos en tecnología, también somos tus consejeros tecnológicos.
                  Ofrecemos servicios de consultoría informática para ayudarte a tomar decisiones informadas y
                  estratégicas. Nuestra experiencia y compromiso con la innovación te guiarán en el camino hacia
                  el éxito tecnológico. Tu visión, nuestra guía.
                </p>
              </div>
            </div>
          </a>
          {/* Box-6 */}
          <a  href="/seguridad-perimetral">
            <div className="mt-4 col-md-6 item-circuit" data-href="./seguridad-perimetral">
              <div className="box">
                <img style={{ width: '15%' }} src="https://rath.mac-softins.com/imgpublic/oficialpage/SEseguridad perimetral.gif" alt="SEGURIDAD PERIMETRAL" className="img-fluid" />
                <h3>SEGURIDAD PERIMETRAL</h3>
                <div className="line m-auto"></div>
                <p>
                  En M@C-SOFTINS, sabemos que la seguridad de tu empresa no se limita a los sistemas internos. Ofrecemos
                  soluciones de seguridad perimetral que protegen tu red desde el exterior. Nuestros expertos se
                  mantienen un paso adelante de las amenazas, asegurando que tu empresa esté blindada contra
                  ataques externos. Tu tranquilidad, nuestra prioridad.
                </p>
              </div>
            </div>
          </a>
          {/* Box-7 */}
          <a  href="/programacion">
            <div className="mt-4 col-md-6 item-circuit" data-href="./programacion">
              <div className="box">
                <img style={{ width: '15%' }} src="https://rath.mac-softins.com/imgpublic/oficialpage/programacion.gif" alt="PROGRAMACIÓN Y DESARROLLO DE SOFTWARE" className="img-fluid" />
                <h3>PROGRAMACIÓN Y DESARROLLO DE SOFTWARE</h3>
                <div className="line m-auto"></div>
                <p>
                  En M@C-SOFTINS, potenciamos tu empresa con nuestros servicios de Programación y Desarrollo de Software.
                  Creamos soluciones personalizadas y eficientes para tus necesidades específicas, garantizando cumplimiento legal, optimización de recursos y ahorro de costos.
                  Confía en nosotros para impulsar tu empresa hacia el futuro tecnológico.
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Section2;
