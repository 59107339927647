import React from "react";
const Cloud = () => {
  return (
    <>
      <section className="s_features_section" style={{ marginTop: "100px" }}>
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="box text-center col-lg-12 m-5 p-5 d-flex flex-column justify-content-center align-items-center">
              <h2
                className="titulos_servicios f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                SERVIDORES Y CLOUD
              </h2>
              <br />
              <h4
                className="lh-20 f_p f_size_20 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                Potencia y Seguridad que Impulsan tu Negocio
              </h4>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="300px"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/security_4.gif"
                  alt="ciberseguridad"
                />
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                <p >
                        En M@C-SOFTINS, sabemos que la correcta gestión de servidores y la eficiencia de las soluciones en la nube son cruciales para la operatividad y el crecimiento de cualquier empresa. <strong> Con más de 6 años de experiencia, </strong> ofrecemos servicios confiables y seguros, diseñados para <strong>maximizar la disponibilidad y el rendimiento de tus aplicaciones y datos críticos.</strong>
                    </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  Configuracion de Servidores:
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Gestión de Servidores Dedicados y Compartidos:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Nos encargamos de la configuración, administración y mantenimiento de tus servidores, asegurando que operen con el máximo rendimiento y seguridad. Nuestros planes incluyen tanto servidores dedicados para necesidades específicas, como opciones compartidas más económicas y eficientes.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
              Soporte Técnico 24/7:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Nuestro equipo técnico está disponible las 24 horas del día, todos los días, para asegurarse de que tus servidores funcionen sin interrupciones. Respondemos rápidamente a cualquier incidencia, minimizando tiempos muertos y maximizando la productividad de tu negocio.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Backup y Recuperación de Datos:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Implementamos soluciones de respaldo y recuperación robustas para proteger tus datos esenciales. En caso de cualquier fallo o pérdida de datos, nuestro sistema de recuperación rápida garantiza que tu negocio continúe operando sin contratiempos.
                </p>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/servidores_2.svg"
                  alt="infraestructura_landing"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/servidores_3.svg"
                  alt="infraestructura_landing"
                />
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                Servicios en la Nube
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Soluciones de Cloud Híbrido y Público:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Ofrecemos opciones de cloud híbrido y público, permitiéndote elegir la configuración que mejor se adapte a tus necesidades y presupuesto. Nuestras soluciones en la nube son escalables, seguras y diseñadas para ofrecer una integración fluida con tus operaciones existentes.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Migración y Gestión en la Nube:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Te apoyamos en la migración de tus operaciones a la nube, asegurando una transición suave y sin interrupciones. Gestionamos todo el proceso, desde la evaluación inicial hasta la implementación completa, para que puedas aprovechar al máximo las ventajas de la nube.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Seguridad y Confianza en la Nube:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Cumplimos con los estándares de seguridad más estrictos y nos aseguramos de que tus datos y aplicaciones estén protegidos contra amenazas externas e internas. Además, garantizamos que nuestras soluciones cumplan con las regulaciones vigentes, ofreciendo tranquilidad y confianza.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s_features_section elegirnos">
        <div className="custom_container">
          <div className="row d-flex justify-content-center align-items-center text-center">
            <h1 className="titulos_servicios">Puntos Diferenciadores</h1>
            <div className="s_features_item_card">
              <div className="card-MaC-SOFTINS wow fadeInLeft">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/contratacion_2.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Profesionalismo</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                Nos distinguimos por un equipo
                de expertos altamente calificados y
                comprometidos, que garantizan la
                calidad y eficiencia en cada proyecto.
                </article>
              </div>
              <div className="card-MaC-SOFTINS wow fadeInRight">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/objetivo.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Atención Personalizada</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                Entendemos que cada cliente es único y
                ofrecemos soluciones que se adaptan
                perfectamente a las necesidades
                específicas de cada empresa.
                </article>
              </div>
              <div className="card-MaC-SOFTINS wow fadeInLeft">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/seguridad.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Experiencia</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <label className="lh-18">
                Con más de una 8 años de
                experiencia, tenemos la capacidad de
                enfrentar y resolver cualquier desafío
                tecnológico eficazmente.
                </label>
              </div>
              <div className="card-MaC-SOFTINS wow fadeInRight">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/mision.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Mejora Continua</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <label className="lh-18">
                Estamos constantemente actualizando y
                mejorando nuestras técnicas y procesos
                para mantener nuestras soluciones a la
                vanguardia de la tecnología y las
                mejores prácticas del sector.
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Cloud;
