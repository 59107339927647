import React from 'react';
const services = [
  {
    imgSrc: "https://rath.mac-softins.com/imgpublic/oficialpage/1s.gif",
    altText: "Servicio 1",
    buttonId: "62X4D7U8MNS3L",
    description: "Pago Por Servicios De Soporte Mayor a 1 hora",
    options: [
      { value: "pago por hora", label: "pago por hora 5,00 USD" }
    ]
  },
  {
    imgSrc: "https://rath.mac-softins.com/imgpublic/oficialpage/2s.gif",
    altText: "Servicio 2",
    buttonId: "DCTV964R8EPNN",
    description: "Configuracion De Servidores Y Servicios",
    options: [
      { value: "configuracion de servidores - 10$", label: "configuracion de servidores - 10$" }
    ]
  },
  {
    imgSrc: "https://rath.mac-softins.com/imgpublic/oficialpage/3s.gif",
    altText: "Servicio 3",
    buttonId: "CMA95WQ4ZMQ2J",
    description: "Configuracion De Firewall",
    options: [
      { value: "configuracion de firewall - 15$", label: "configuracion de firewall - 15$" }
    ]
  },
  {
    imgSrc: "https://rath.mac-softins.com/imgpublic/oficialpage/4s.webp",
    altText: "Servicio 4",
    buttonId: "7DZV4S44CLRGS",
    description: "Asesoramiento En Proyectos Tecnologicos Cada Hora",
    options: [
      { value: "Asesoramiento En Proyectos Tecnologicos - 16$", label: "Asesoramiento En Proyectos Tecnologicos - 16$" }
    ]
  },
  {
    imgSrc: "https://rath.mac-softins.com/imgpublic/oficialpage/5s.png",
    altText: "Servicio 5",
    buttonId: "QNXTCLWGPGMCU",
    description: "configuracion de contenedores docker, por contenedor",
    options: [
      { value: "configuracion de contenedores docker-15$", label: "configuracion de contenedores docker-15$" }
    ]
  },
  {
    imgSrc: "https://rath.mac-softins.com/imgpublic/oficialpage/6s.jpg",
    altText: "Servicio 6",
    buttonId: "DWJ44EHLES5C8",
    description: "Asesoramiento y Configuracion De Servidor Pfsense, la Hora",
    options: [
      { value: "Configuracion De Servidor Pfsense-10$", label: "Configuracion De Servidor Pfsense-10$" }
    ]
  },
  {
    imgSrc: "https://rath.mac-softins.com/imgpublic/oficialpage/7s.png",
    altText: "Servicio 7",
    buttonId: "N2ZLKANDDAV6E",
    description: "Asesoramiento y Configuracion de servidores vmware vsphere por hora",
    options: [
      { value: "Configuracion de servidores vmware vsphere-30$", label: "Configuracion de servidores vmware vsphere-30$" }
    ]
  },
  {
    imgSrc: "https://rath.mac-softins.com/imgpublic/oficialpage/8s.png",
    altText: "Servicio 8",
    buttonId: "5RBCNBBV48JZ2",
    description: "Configuracion de Servidores Proxmox la hora",
    options: [
      { value: "Configuracion de Servidores Proxmox-20$", label: "Configuracion de Servidores Proxmox-20$" }
    ]
  }
];
const ServiceCard = ({ imgSrc, altText, buttonId, description, options }) => (
  <div className="col-lg-3 col-md-3 col-sm-12 carpaypal">
    <div className="f_icon">
      <img src={imgSrc} alt={altText} />
    </div>
    <div className="form-group">
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value={buttonId} />
        <table>
          <tbody>
            <tr>
              <td>
                <input type="hidden" name="on0" value={description} />
                {description}
              </td>
            </tr>
            <tr>
              <td>
                <select name="os0" className="form-control">
                  {options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <input type="hidden" name="currency_code" value="USD" />
        <br />
        <center>
          <input
            type="image"
            src="https://www.paypalobjects.com/es_XC/i/btn/btn_buynow_LG.gif"
            border="0"
            name="submit"
            title="PayPal es una forma segura y fácil de pagar en línea."
            alt="Agregar al carrito"
          />
        </center>
      </form>
    </div>
  </div>
);

const ServicesGrid = () => (
  <div style={{ paddingLeft: '10%' }}>
  <div className="section-header text-center">
    <h1> Pagos y Contratacion De Servicios</h1>
  </div>

    <div className="rowdatosss">
      {services.map(service => (
        <ServiceCard key={service.buttonId} {...service} />
      ))}
    </div>
  </div>
);

export default ServicesGrid;
