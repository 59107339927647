import React from "react";

const Infraestructura = () => {
  return (
    <>
      <div className="section_infraestructura">
        <section className="s_features_section" style={{ marginTop: "100px" }}>
          <div className="container custom_container">
            <div className="row s_features_item">
              <div className="box text-center col-lg-12 m-5 p-5 d-flex flex-column justify-content-center align-items-center">
                <h2
                  className="titulos_servicios f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  INFRAESTRUCTURA
                </h2>
                <br />
                <h4
                  className="lh-20 f_p f_size_20 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                  Especialistas en la creación, gestión y optimización de infraestructuras
                </h4>
              </div>

              <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <div
                  className="s_features_img ml_50 wow fadeInLeft"
                  data-wow-delay="0.3s"
                >
                  <img
                    width="300px"
                    src="https://rath.mac-softins.com/imgpublic/oficialpage/security_1.gif"
                    alt="ciberseguridad"
                  />
                </div>
              </div>

              <div className="col-lg-6 d-flex align-items-center">
                <div className="text-azul s_features_content pl_120 text-justify">
                  <p
                    className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    En M@C-SOFTINS, somos especialistas en la{" "}
                    <strong> creación, gestión y optimización de infraestructuras de red </strong>
                    con más de 15 años de experiencia. Nuestro objetivo es proporcionar soluciones
                    de red que aseguren una operación eficiente y segura,{" "}
                    <strong>adaptándonos a las necesidades específicas de cada cliente</strong> y
                    alineados con sus objetivos empresariales.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="s_features_section">
          <div className="container custom_container">
            <div className="row s_features_item">
              <div className="col-lg-6 d-flex align-items-center">
                <div className="text-azul s_features_content pl_120 text-justify">
                  <h2
                    className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                    data-wow-delay="0.4s"
                  >
                    Servicios Detallados:
                  </h2>
                  <h3
                    className="f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Diseño e Instalación de Infraestructuras de Red:
                  </h3>
                  <p
                    className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Desarrollamos infraestructuras de red cableadas e inalámbricas desde cero,
                    asegurando una instalación precisa y adaptada a las necesidades actuales y
                    futuras de tu empresa.
                  </p>

                  <h3
                    className="f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Mantenimiento y Soporte Técnico de Redes:
                  </h3>
                  <p
                    className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Ofrecemos mantenimiento preventivo y correctivo para redes, actuando rápidamente
                    para resolver incidencias y mantener tu red operando sin interrupciones.
                  </p>

                  <h3
                    className="f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Monitoreo y Gestión Proactiva de Redes:
                  </h3>
                  <p
                    className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Implementamos sistemas avanzados de monitoreo 24/7 para detectar y resolver
                    proactivamente problemas antes de que afecten tu negocio.
                  </p>

                  <h3
                    className="f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Optimización y Modernización de Red:
                  </h3>
                  <p
                    className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Evaluamos y mejoramos tu infraestructura de red para garantizar la máxima
                    eficiencia, seguridad y escalabilidad.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <div
                  className="s_features_img ml_50 wow fadeInLeft"
                  data-wow-delay="0.3s"
                >
                  <img
                    width="100%"
                    src="https://rath.mac-softins.com/imgpublic/oficialpage/infraestructura_2.svg"
                    alt="infraestructura_landing"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="s_features_section">
          <div className="container custom_container">
            <div className="row s_features_item">
              <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <div
                  className="s_features_img ml_50 wow fadeInLeft"
                  data-wow-delay="0.3s"
                >
                  <img
                    width="100%"
                    src="https://rath.mac-softins.com/imgpublic/oficialpage/infraestructura_3.svg"
                    alt="infraestructura_landing"
                  />
                </div>
              </div>

              <div className="col-lg-6 d-flex align-items-center">
                <div className="text-azul s_features_content pl_120 text-justify">
                  <h2
                    className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                    data-wow-delay="0.4s"
                  >
                    Enfoque en la <br />
                    Personalización::
                  </h2>
                  <h3
                    className="f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Cada Empresa es un Reto Único:
                  </h3>
                  <p
                    className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Trabajamos de cerca con cada cliente para entender sus retos específicos de red y ofrecer soluciones hechas a la medida.
                  </p>

                  <h3
                    className="f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Soluciones de Red Personalizadas:
                  </h3>
                  <p
                    className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Cada aspecto de nuestras soluciones de red es diseñado pensando en los requisitos y objetivos particulares de cada cliente.
                  </p>

                  <h3
                    className="f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Adaptabilidad y Escalabilidad:
                  </h3>
                  <p
                    className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    Nuestras infraestructuras están diseñadas para adaptarse y crecer con tu empresa, asegurando una inversión a largo plazo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Infraestructura;
