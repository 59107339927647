import React from "react";
const Section = () => {
  return (
    <>
      <section className="s_features_section" style={{ marginTop: "100px" }}>
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="box text-center col-lg-12 m-5 p-5 d-flex flex-column justify-content-center align-items-center">
              <h2
                className="titulos_servicios f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                SEGURIDAD PERIMETRAL
              </h2>
              <br />
              <h4
                className="lh-20 f_p f_size_20 f_700 t_color l_height45 wow fadeInRight"
                data-wow-delay="0.4s"
              >
                Tu Primera Línea de Defensa
              </h4>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="300px"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/security_5.gif"
                  alt="ciberseguridad"
                />
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                En M@C-SOFTINS, entendemos que la seguridad perimetral es más que solo una barrera física; es una defensa esencial en
                <strong>la protección de los activos digitales y físicos de tu empresa.</strong>
                Con nuestra amplia experiencia, ofrecemos soluciones de seguridad perimetral avanzadas,
                 <strong>adaptadas para proteger a tu empresa contra amenazas externas e internas.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="s_features_section">
        <div className="container custom_container">
          <div className="row s_features_item">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-azul s_features_content pl_120 text-justify">
                <h2
                  className="text-uppercase f_p f_size_30 f_700 t_color l_height45 wow fadeInRight"
                  data-wow-delay="0.4s"
                >
                Servicios de <br />
                Seguridad Perimetral
                </h2>
                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Análisis y Diseño de Seguridad:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Comenzamos con un análisis exhaustivo de tus necesidades de seguridad, seguido por el diseño de un sistema de seguridad perimetral
                  personalizado que aborde específicamente las vulnerabilidades de tu infraestructura.
                  Nuestro enfoque holístico garantiza una cobertura completa, protegiendo todos los puntos de acceso.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Implementación de Sistemas de Vigilancia:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Instalamos sistemas de vigilancia de última generación, incluyendo cámaras de seguridad CCTV,
                   sensores de movimiento y alarmas. Estos sistemas no solo actúan como un disuasivo contra los intrusos,
                   sino que también proporcionan evidencia crucial en caso de un incidente.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Control de Acceso Físico y Digital:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Reforzamos la seguridad perimetral con soluciones de control de acceso que regulan quién puede
                 ingresar a tus instalaciones y a tus sistemas informáticos.
                 Desde cerraduras electrónicas hasta autenticación biométrica,
                 nuestras soluciones aseguran que solo el personal autorizado tenga acceso.
                </p>

                <h3
                  className="f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                  Mantenimiento y Actualización Constante:
                </h3>
                <p
                  className="lh-18 f_size_18 l_height30 f_400 wow fadeInRight"
                  data-wow-delay="0.5s"
                >
                Evaluamos y mejoramos tu infraestructura de red para garantizar la máxima eficiencia, seguridad y escalabilidad.
                </p>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div
                className="s_features_img ml_50 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  width="100%"
                  src="https://rath.mac-softins.com/imgpublic/oficialpage/seguridad-perimetral.svg"
                  alt="infraestructura_landing"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="s_features_section elegirnos">
        <div className="custom_container">
          <div className="row d-flex justify-content-center align-items-center text-center">
            <h1 className="titulos_servicios">Beneficios de Trabajar Con Nosotros</h1>
            <div className="s_features_item_card">
              <div className="card-MaC-SOFTINS wow fadeInLeft">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/certificado.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Certificados</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                Nuestro equipo está compuesto por
                expertos en seguridad que están
                altamente capacitados y
                comprometidos con ofrecer la
                máxima protección.
                </article>
              </div>
              <div className="card-MaC-SOFTINS wow fadeInRight">
                <img src="https://rath.mac-softins.com/imgpublic/oficialpage/tasa.gif" className="imgCard" alt="img-eligirnos" />
                <h3 className="text-uppercase">Atención Personalizada</h3>
                <div className="hr-MaC-SOFTINS"></div>
                <article className="lh-18">
                Cada solución de seguridad que
                diseñamos es única, basada en las
                necesidades específicas de seguridad de
                cada cliente, asegurando un enfoque que
                se ajuste perfectamente a tus requisitos.
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section;
