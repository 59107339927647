// src/context/GlobalContext.js

import React, { createContext, useState, useEffect } from 'react';

// Crear el contexto
export const GlobalContext = createContext();

// Crear el proveedor del contexto
export const GlobalProvider = ({ children }) => {
  const [estadisticas, setEstadisticas] = useState(null);

  // Recuperar estadísticas del Local Storage al montar el proveedor
  useEffect(() => {
    const storedEstadisticas = localStorage.getItem('estadisticas');
    if (storedEstadisticas) {
      setEstadisticas(JSON.parse(storedEstadisticas));
    }
  }, []);

  return (
    <GlobalContext.Provider value={{ estadisticas, setEstadisticas }}>
      {children}
    </GlobalContext.Provider>
  );
};
