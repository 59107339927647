import React, { useEffect, useState } from 'react';

const ModalNoticias = ({ isOpen, onClose, noticias }) => {
  const [shouldShowModal, setShouldShowModal] = useState(false);

  useEffect(() => {
    if (!noticias || !noticias.url) {
      // Si no hay noticias o el ID es inválido, no mostrar el modal
      setShouldShowModal(false);
      return;
    }

    const noticiaId = noticias.url; // Obtenemos el ID de la noticia actual

    // Obtener el array de IDs de noticias vistas del localStorage (si existe)
    const viewedNoticias = JSON.parse(localStorage.getItem('viewedNoticias')) || [];

    // Comprobar si el ID de la noticia actual está en el array de noticias vistas
    if (!viewedNoticias.includes(noticiaId)) {
      setShouldShowModal(true); // Mostrar el modal si no ha sido visto
    } else {
      setShouldShowModal(false); // No mostrar el modal si ya ha sido visto
    }
  }, [isOpen, noticias]);

  if (!shouldShowModal || !isOpen) return null; // No renderizar el modal si no debe mostrarse o si isOpen es false

  // Toma solo la noticia actual
  const noticia = noticias;

  const handleButtonClick = () => {
    const noticiaId = noticia.url; // Usamos el ID de la noticia actual

    // Verificar si el ID de la noticia es válido
    if (!noticiaId) {
      onClose();
      return;
    }

    // Obtener el array de IDs de noticias vistas del localStorage
    const viewedNoticias = JSON.parse(localStorage.getItem('viewedNoticias')) || [];

    // Si el ID de la noticia no está en el array, agregarlo y abrir la URL
    if (!viewedNoticias.includes(noticiaId)) {
      viewedNoticias.push(noticiaId);
      localStorage.setItem('viewedNoticias', JSON.stringify(viewedNoticias));

      // Si la noticia tiene una URL, abrirla en una nueva ventana
      if (noticia.url) {
        window.open(noticia.url, '_blank');
      }
    }

    onClose(); // Cierra el modal
  };

  return (
    <div className="modal-overlay-noticia">
      <div className="modal-content-noticia">
        {noticia ? (
          <>
            <div className="modal-header-noticia">
              <center>
                <h3 className="h3mod">{noticia.nombre}</h3>
              </center>
            </div>
            <div className="modal-body-noticia">
              {noticia.tipo === 'imagen' ? (
                <img
                  src={`https://rath.mac-softins.com/${noticia.path}`}
                  alt={noticia.nombre}
                  className="modal-image"
                />
              ) : (
                <div className="video-container">
                  <video
                    controls
                    autoPlay
                    loop
                    className="modal-video"
                    style={{ width: '100%' }}
                  >
                    <source src={`https://rath.mac-softins.com/${noticia.path}`} type="video/mp4" />
                    Tu navegador no soporta la etiqueta de video.
                  </video>
                </div>
              )}
            </div>
          </>
        ) : (
          <p>No hay noticias disponibles.</p>
        )}
        <div className="modal-footer">
          <button className="btn btn-main-2" onClick={handleButtonClick}>
            <i className="fa fa-external-link"></i> Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalNoticias;
