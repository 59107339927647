import React from 'react';

const Row360Section = () => {
  const handleIframeLoad = () => {
    console.log('Iframe cargado');
  };

  return (
    <div className="row360section">
      {/* Primera columna: Iframe VR */}
      <div className="col-lg-8 col-md-12 col-xl-8">
        <div className="card360" style={{ width: '100%', height: '100%' }}>
          <div className="card-body" style={{ padding: 0, width: '100%', height: '100%' }}>
          <iframe
            width="100%"
            height="450"
            allowFullScreen
            frameBorder="0"
            src="https://storage.googleapis.com/vrview/2.0/index.html?image=//e-lexia.com/blog/wp-content/uploads/360.jpg&is_stereo=true"
            style={{
              margin: '2em auto',
              display: 'block',
              boxShadow: '4px 4px 10px #CCC',
              width: '100%',
              height: '100%',
            }}
            onLoad={handleIframeLoad} // Llama a la función cuando el iframe carga
            title="VR View"
          ></iframe>
          </div>
          <div className="cardfoot360">
            <h3> VR-Orbit360° </h3>
          </div>
        </div>
      </div>

      {/* Segunda columna: Imagen */}
      <div className="col-lg-4 col-md-12 col-xl-4">
        <div className="card-body-360">
          <div className="img-card">
            <a href="/ceo">
              <img
                src="https://rath.mac-softins.com/imgpublic/oficialpage/kirito%20meta.png"
                alt="Descripción de la imagen"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row360Section;
