import React from 'react';
import Slider from 'react-slick';


const Client = () => {
  // Configuración del slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="section clients">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h2>Trabajamos  con las marcas mas reconocidas en el mercado</h2>
              <div className="divider mx-auto my-4"></div>
              <p>
              Trabajamos con las mejores marcas del mercado, garantizando productos y servicios de alta calidad que cumplen con los más altos estándares.
              Nuestra colaboración con líderes de la industria nos permite ofrecer soluciones
              innovadoras y confiables que se adaptan a las necesidades de nuestros clientes</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-body">
      <Slider {...settings}>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/C/cisco-logo-DDA7EFD10D-seeklogo.com.png" alt="Cisco" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/D/dahua-logo-43857495F7-seeklogo.com.png" alt="Dahua" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/H/huawei-logo-A8D49F5E99-seeklogo.com.png" alt="Huawei" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/M/microsoft-logo-B5C9E49146-seeklogo.com.png" alt="Microsoft" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/H/hp-hewlett-packard-logo-C8F2A37559-seeklogo.com.png" alt="HP" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/D/dell-emc-logo-C1AF1ABF06-seeklogo.com.png" alt="Dell" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/H/hikvision-logo-BA7C30E188-seeklogo.com.png" alt="hikvision" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/L/lenovo-logo-E857A0C4D2-seeklogo.com.png" alt="lenovo" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/E/ESET_NOD32_Antivirus-logo-0C5A57C98D-seeklogo.com.png" alt="ESET_NOD32_Antivirus" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/Z/zoom-logo-383DA4B5BA-seeklogo.com.png" alt="zoom" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/S/samsung-logo-8A87EDFB33-seeklogo.com.png" alt="samsung" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/L/LG_Electronics-logo-DDDB1A917D-seeklogo.com.png" alt="lg" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/C/cat-machinery-logo-0D2946DA1A-seeklogo.com.png" alt="cat" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://seeklogo.com/images/T/tp-link-nuevo-logo-AD5775E6CE-seeklogo.com.png" alt="tplink" className="img-fluid" />
        </div>
        <div className="client-thumb">
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvx2dL1mzFqaRwRDwNSDp4JYbM79aIay0Ic4-Qsfkz3cJ_Bd5o3qymXJhf1TfcKpQ7ahM" alt="zkteco" className="img-fluid" />
        </div>
      </Slider>

      </div>
    </section>
  );
};

export default Client;
